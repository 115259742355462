import React, { Component } from 'react';
import { Divider, Button, Row, Icon,Col } from 'antd';
import * as utils from "../../../common/utils/utils";
import "./RouteTracking.scss";
import * as moment from "moment";

class HeliosStepper extends Component {
  constructor(props) {
    super(props)
    this.state = {
        stepList: null,
        stepKey: null,
    }
  }

  
  getDiscription = (isSimTracking,name, sTime, eTime, halts, delay, key, circleClass, status, tileSet, setN) => {
      return (
          <>
          <Row key={key}>
            <div className="clearing">
                <div className="left_box">
                <div className={circleClass}>{setN ? <div className="whiteLetter">{String.fromCharCode(setN)}</div> : <div className="whiteLetter">{String.fromCharCode(32)}</div>}</div>
                    {status === "PENDING" && <div className={(circleClass === "redcircle") ? "lineset2" : "lineset4"}></div>}
                    {status === "ARRIVED" && <div className={(circleClass === "redcircle") ? "lineset2" : "lineset"}></div>}
                    {status === "LATEST_LOCATION" && <div className={(circleClass === "redcircle") ? "lineset2" : "lineset4"}></div>}
                    {status === "PENDING_CUST_LOC" && <div className={(circleClass === "redcircle") ? "lineset5" : "lineset2"}></div>}
                </div>
                <div className="right_box">
                    {delay ? <div className="top-row">
                        <div className="step-title">{name}</div>
                        <div className="delays">Delay : {this.getHours(delay)}</div>
                    </div> : <div className="step-title2">{name}</div>}
                    {/* {sTime ? <div className="dateSet">{tileSet} : {utils.dateFormat(sTime)}</div>:<div></div>}
                    {eTime ? <div className="dateSet">Departed At : {utils.dateFormat(eTime)}</div>:<div></div>} */}
                              
                        {sTime && eTime ? <div className="dateSet float_left">{utils.dateFormat(sTime)} - {utils.dateFormat(eTime)}</div>:<div className="dateSet">{tileSet} : {utils.dateFormat(sTime)}</div>}
                        {sTime && eTime ? <div className="stop_color float_right">
                            <label>
                                Stop: 
                            </label>
                            {this.getHours(utils.dateTimeStampFormat(eTime).diff(utils.dateTimeStampFormat(sTime),'minutes'))}
                            </div> :<div></div>}
                     
                    {halts && halts.length > 0 && !isSimTracking? <Button type="dashed" className="buttondashed" shape="round" onClick={() => this.getHaltsRoute(halts, key)}>View Halts
                        {this.state.stepKey === key ? <Icon className="iconAdj" type="up" /> : <Icon className="iconAdj" type="down" />}
                    </Button>:<div></div>}
                    {halts && halts.length > 0 && isSimTracking ? <Button type="dashed" className="buttondashed" shape="round" onClick={() => this.getSimTrackHaltsRoute(halts, key)}>View Halts
                        {this.state.stepKey === key ? <Icon className="iconAdj" type="up" /> : <Icon className="iconAdj" type="down" />}
                    </Button>:<div></div>}
                </div>
            </div>
          </Row>
          <Row>
            <div className="greyBackground">
            {this.state.stepKey === key && this.state.stepList}
            </div>
        </Row>
        </>
      )
  }

  getHours =(n) => {
        let num = n;
        let hours = (num / 60);
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        let final = (rhours < 1) ? rminutes + "m" : rhours + "hr " + rminutes + "m";
        return final
  }

  getDiscriptionNext = (name, sTime, eTime, distance, key) => {
        return (
            <Row key={key}>
                <div className="clearing">
                <div className="left_box">
                    <div className="bluecircle"></div>
                    <div className="lineset3"></div>
                </div>
                <div className="right_box">
                    <div className="step-title2">{name}</div>  
                      <Row>               
                        <div className="dateSet float_left">{utils.dateFormat(sTime)} - {utils.dateFormat(eTime)}</div> 
                        <div className="stop_color float_right">
                            <label>
                                Stop: 
                            </label>
                            {this.getHours(utils.dateTimeStampFormat(eTime).diff(utils.dateTimeStampFormat(sTime),'minutes'))}
                            </div> 
                        </Row>     
                </div>
                </div>
            </Row>
        )
    }

    getSimDiscriptionNext = (name,sTime,key) => {
        return (
            <Row key={key}>
                <div className="clearing">
                <div className="left_box">
                    <div className="bluecircle"></div>
                    <div className="lineset3"></div>
                </div>
                <div className="right_box">
                    <div className="step-title2">{name}</div>  
                    <Row>               
                    {sTime>0 && <div className="dateSet">Arrived at: {utils.dateFormat(sTime)}</div>}  
                    </Row>                  
                </div>
                </div>
            </Row>
        )
    }

    getHaltsRoute = (halts, key) => {
        if(this.state.stepList === null || key !== this.state.stepKey){
            let haltsData;
            haltsData = halts.map((x, key) => {
                var name = (x.location).replace(/@!@/g,",");
                return this.getDiscriptionNext(name, parseInt(x.startTime), parseInt(x.endTime), Math.round(x.distanceCovered), key)
            })
            this.setState({stepList : haltsData, stepKey: key});
        } else {
            this.setState({stepList : null, stepKey: null});
            return;
        }
    }

    getSimTrackHaltsRoute = (halts, key) => {
        if((this.state.stepList === null || key !== this.state.stepKey) && !utils.isEmpty(halts)){
            let haltsData;
            haltsData = halts.map((x, key) => {
                return this.getSimDiscriptionNext(x.name,x.arriveAt, key)
            })
            this.setState({stepList : haltsData, stepKey: key});
        } else {
            this.setState({stepList : null, stepKey: null});
            return;
        }
    }

    getcurrent = (data) => {
        return (
            <>
                <Row key="currentLocation">
                    <div className="clearing">
                        <div className="left_box">
                            <div className="bluecirclebox"></div>
                            <div className="lineset4"></div>
                        </div>
                        <div className="right_box">
                            <div className="currentPosition">{data}</div>
                        </div>
                    </div>
            </Row>
            </>
        )
    }

    getHaltingPoints = (stoppagePoints, index) => {
        let halts = [];
        for (var i = index; i < stoppagePoints.length; i++) {
            if (!stoppagePoints[i].customerLocation) {
                halts.push(stoppagePoints[i]);
            } else {
                break;
            }
        }
        return halts;
    }

    routingList(stoppagePoints, customerLocations, startingLocation,lastKnownLocation, sourceHubLocation) {
      let setDummy = 64;
      let routeCard = [];
      var key = 0;
      if(!utils.isEmpty(sourceHubLocation) && !utils.isEmpty(sourceHubLocation.address)){
        routeCard.push(this.getDiscription(false,sourceHubLocation.address, null, null,null, null, key, "bluecircle", "ARRIVED", "Started at"))
      }
      if(!utils.isEmpty(startingLocation) && !utils.isEmpty(startingLocation.address)){
        key = key + 1;
        let haltingPoints = this.getHaltingPoints(stoppagePoints,0);
        routeCard.push(this.getDiscription(false,startingLocation.address, startingLocation.timeStamp, null,haltingPoints, null, key, "bluecircle", "ARRIVED", "Started at"))
      }
      var count = 0;
      stoppagePoints.forEach(location =>{
        if(location.customerLocation && !utils.isEmpty(location.location)){
            key = key + 1;
            let haltingPoints = this.getHaltingPoints(stoppagePoints,count+1);
            routeCard.push(this.getDiscription(false,location.location, location.startTime, location.endTime,haltingPoints, null, key, "bluecircle", "ARRIVED", "Arrived at"))
            count = count + 1;
        }else{
            count = count + 1;
        }

      })

      var unReachecCustomerLocations =  customerLocations.filter(location =>{
        return !location.reached;
      })

      if (!utils.isEmpty(lastKnownLocation) && !utils.isEmpty(lastKnownLocation.address)) {
            let currentLocation = {};
            if (unReachecCustomerLocations.length > 0) {
                key = key + 1;
                currentLocation = <>
                    {this.getDiscription(false, lastKnownLocation.address, lastKnownLocation.timeStamp, null, null, null, key, "blueboxcircle", "LATEST_LOCATION", "Updated at")}
                </>
            } else {
                key = key + 1;
                currentLocation = <>
                    {this.getDiscription(false, lastKnownLocation.address, lastKnownLocation.timeStamp, null, null, null, key, "redcircle", "LATEST_LOCATION", "Updated at")}
                </>
            }
            routeCard.push(currentLocation);
     }   
       var count = 0;
       unReachecCustomerLocations.forEach(location =>{
           if(!location.reached && !utils.isEmpty(location.name)){
            key = key + 1;
            //routeCard.push(this.getDiscription(false,location.name, null, null, null, null, key, "redcircle", "PENDING", "Not yet reached"))
            if(unReachecCustomerLocations.length-1 === count) {
                routeCard.push(this.getDiscription(false,location.name, null, null, null, null, key, "redboxcircle", "PENDING_CUST_LOC", "Not yet reached"))
              }else{
                routeCard.push(this.getDiscription(false,location.name, null, null, null, null, key, "redcircle", "PENDING_CUST_LOC", "Not yet reached"))
              } 
            count = count +1;
           }

       })
        return routeCard;
    }

    simRoutingList(stoppagePoints, customerLocations, startingLocation,lastKnownLocation, sourceHubLocation) {
        let setDummy = 64;
        let routeCard = [];
        var key = 0;
        var unreachedCustomerLocations = [];
        if(!utils.isEmpty(sourceHubLocation) && !utils.isEmpty(sourceHubLocation.address)){
            routeCard.push(this.getDiscription(true,sourceHubLocation.address, null, null,null, null, key, "bluecircle", "ARRIVED", "Started at"))
            key = key +1;
        }
        if(!utils.isEmpty(startingLocation) && !utils.isEmpty(startingLocation.address)){
            routeCard.push(this.getDiscription(true,startingLocation.address, startingLocation.timeStamp, null,null, null, key, "bluecircle", "ARRIVED", "Started at"))
            key = key +1;
        }

        customerLocations.forEach(location =>{
            if(location.reached){
               routeCard.push(this.getDiscription(true,location.name, location.arriveAt, location.departedAt, null, null, key, "bluecircle", "ARRIVED", "Arrived at"))
               key = key + 1;
            }else{
                unreachedCustomerLocations.push(location);
             }  
            
        })
        
        let currentLocation = {};
        if(unreachedCustomerLocations.length > 0 && !utils.isEmpty(lastKnownLocation) && !utils.isEmpty(lastKnownLocation.address)){          
            currentLocation = <>
            {this.getDiscription(true,lastKnownLocation.address, lastKnownLocation.timeStamp, null, null, null, key, "blueboxcircle", "LATEST_LOCATION", "Updated at")}
            </>      
        }else if(!utils.isEmpty(lastKnownLocation) && !utils.isEmpty(lastKnownLocation.address)){   
            currentLocation = <>
        {this.getDiscription(true,lastKnownLocation.address, lastKnownLocation.timeStamp, null, null, null, key, "redcircle", "LATEST_LOCATION", "Updated at")}
        </> 
        }

        if(!utils.isEmpty(currentLocation)){
            routeCard.push(currentLocation);
            key = key + 1;
        }
         
         var count = 0;
         unreachedCustomerLocations.forEach(location =>{
             if(!location.reached){
              if(unreachedCustomerLocations.length-1 === count) {
                routeCard.push(this.getDiscription(true,location.name, null, null, null, null, key, "redboxcircle", "PENDING_CUST_LOC", "Not yet reached"))
              }else{
                routeCard.push(this.getDiscription(true,location.name, null, null, null, null, key, "redcircle", "PENDING_CUST_LOC", "Not yet reached"))
              }  
              count = count +1;
              key = key + 1;
             }
  
         })
          return routeCard;
      }
    getTimeStamp = (data) =>{
        if(data.simTracking){
          return moment(data.response.vehicleTrackingContractVO.startDate).format('x')
        }else{
          return parseInt(data.response.tripStart.startTime)
        }
    }
  render() {
      const { tripData } = this.props;
      let routeList;
      let stoppagePoints= [];
      let customerLocations = [];
      let startingLocation = {};
      let sourcehubLocation = {};
      let lastKnownLocation = {};
      let isSimTracking = false;
      let isStepperAllowed  = false;
      if(!utils.isEmpty(tripData) && !Array.isArray(tripData?.responses)){
          if (!utils.isEmpty(tripData) && tripData.simTracking) {
              isSimTracking = true;
              lastKnownLocation = tripData.lastKnownLocation
              startingLocation = tripData.startingLocation
              sourcehubLocation = tripData.sourceHubLocation
              customerLocations = tripData.customerLocations
              isStepperAllowed = true;
          } else if (!utils.isEmpty(tripData) && !tripData.simTracking) {
              stoppagePoints = tripData.stoppagePoints;
              lastKnownLocation = tripData.lastKnownLocation
              startingLocation = tripData.startingLocation
              sourcehubLocation = tripData.sourceHubLocation
              isStepperAllowed = true;
              tripData.customerLocations.forEach(location => {
                  if (!location.reached) {
                      customerLocations.push(location);
                  }
              })
          }
      }
      else if(!utils.isEmpty(tripData)){
        if(tripData && tripData?.responses?.length > 0){
              var sortedResponses = tripData.responses.sort((a,b) =>{
                let first = this.getTimeStamp(a);
                let second = this.getTimeStamp(b);
                return first - second;
              });
              var count = 0;
              sortedResponses.forEach(data=>{
                if(data.simTracking){
                    if(data.response?.reportResponse && data.response.reportResponse.length > 0){
                        isStepperAllowed = isStepperAllowed || true
                      }
                }else{
                    if(data.response?.locationPoints && data.response.locationPoints.length > 0){
                        isStepperAllowed = isStepperAllowed || true
                      }
                }

                if(count==0){
                  startingLocation = data.startingLocation;
                  sourcehubLocation = data.sourceHubLocation
                }
                if(count == sortedResponses.length-1){
                  lastKnownLocation = data.lastKnownLocation;
                  isSimTracking = data.simTracking;
                  if(data.futurePolyLine){
                    isStepperAllowed = isStepperAllowed || true
                  }
                }
                if(data?.customerLocations && data.customerLocations.length > 0 && count == sortedResponses.length-1){
                  customerLocations = customerLocations.concat(data.customerLocations.filter(dt=>{
                      return !dt.reached
                  }))
                 }

                if(data.simTracking){
                    stoppagePoints = stoppagePoints.concat(data.customerLocations.filter(dt=>{
                        if(dt.reached){
                            dt.customerLocation = true;
                            dt.location = dt.name;
                            dt.startTime = dt.arriveAt;
                            return true;
                        }
                    }))
                    customerLocations = customerLocations.concat(data.customerLocations.filter(dt=>{
                        return dt.reached
                    }))
                }else{
                    if(data?.stoppagePoints && data.stoppagePoints.length > 0){
                        stoppagePoints = stoppagePoints.concat(data.stoppagePoints)
                    }
                }
                    

                
                count = count +1;
              }) 
          }
      }
      
      if(tripData && !isSimTracking){
        routeList = this.routingList(stoppagePoints , customerLocations, startingLocation, lastKnownLocation,sourcehubLocation);
      }else if(tripData && isSimTracking){
        routeList = this.simRoutingList(stoppagePoints, customerLocations, startingLocation, lastKnownLocation,sourcehubLocation);
      }
    return (
        <div>
        {routeList && isStepperAllowed &&
            <div>
                {routeList}
            </div>
        }
      </div>
    );
  }
}

export default HeliosStepper;