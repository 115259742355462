import { combineReducers } from 'redux';
import { appReducer } from '../app/appReducer'
import { headerReducer } from '../components/shared/header/reducer';

import { lsnalertReducer } from '../components/shared/Lsnalert/reducer';
import { ZoneReducer } from '../components/shared-components/zone/reducer';
import { ZoneMappingReducer } from '../components/shared-components/zone-mapping/reducer';
import { PromiseTimeReducer } from '../components/shared-components/customer-promise-time/reducer';
import { CustomerUserReducer } from '../components/customer-users/reducer'
import { LiveLoadsReducer } from '../components/customer-panel/live-loads/reducer';
import { DeliverLoadsReducer } from '../components/customer-panel/delivered-loads/reducer';
import { CustomerTrackingReducer } from '../components/customer-tracking/reducer';
import { lovStore } from '../common/lovStore/lovReducer';

const rootReducer = combineReducers({
    app: appReducer,
    header: headerReducer,
    
    lsnalert: lsnalertReducer,
    zone: ZoneReducer,
    zoneMapper: ZoneMappingReducer,
    promiseTime: PromiseTimeReducer,
    customerUsers: CustomerUserReducer,
    liveLoads: LiveLoadsReducer,
    deliverLoads: DeliverLoadsReducer,
    customerTracking: CustomerTrackingReducer,

    lovStore : lovStore
});

export default rootReducer;
