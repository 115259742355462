import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsPane from "../../components/shared/TabsPane/TabsPane";
import Lsnalert from "../../components/shared/Lsnalert/Lsnalert";
import Sidebar from "../../components/shared/sidebar/Sidebar";
import Header from "../../components/shared/header/Header";
import RouteWithTitle from "../RouteWithTitle";
import "../app.scss";
// import AuditNotice from "../../components/inventory/audit/audit-notice";
import { canAllow } from "../../common/utils/utils";
import AccessDenied from "../../components/authentication/access/AccessDenied";

class ToggleSidebarLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidesidebar: true,
    }
  }

  componentDidMount() {
    // setTimeout(() => { this.setState({ hidesidebar: !this.state.hidesidebar }) }, 500);
  }

  render() {
    const { path, component, name, access } = this.props;
    return (
      <>
        <Header {...this.props}></Header>
        <Layout>
          {/* {this.state.hidesidebar && <Sidebar {...this.props} />} */}
          <Layout className="padding-container">
            {/* <AuditNotice /> */}
            {/* <Lsnalert></Lsnalert> */}
            <Layout className="tabs-container">
              <TabsPane  {...this.props} />
            </Layout>
            <Layout>
              {path && component && <RouteWithTitle key={name} exact={true} path={path}
               component={access === undefined || canAllow(access) ? component : AccessDenied } name={name} />}
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(ToggleSidebarLayout))
);
