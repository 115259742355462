import React, { Component } from "react";
import { Layout, Menu } from "antd";
import { menuconfig } from "../../../configs/menu-config";
import { angularUrl, reactUrl } from "../../../environments/node-react-environment";
import { icon as Icon } from '../../ui/icon/Icon';
import './Sidebar.scss';
const { Sider } = Layout;

class Sidebar extends Component {
  state = {
    menuItems: [],
    selectedMenuKey: null,
  };

  componentDidMount() {
    if (this.props.location.pathname !== "/appv2/") {
      this.selectMenuAndTabForValidUrl();
    }
  }

  selectMenuAndTabForValidUrl = () => {
    if (!this.props.history.location.pathname) {
      return;
    }
    const pathParts = this.props.history.location.pathname.split("/");
    const tabPath = pathParts[pathParts.length - 1]
    const menuPath = pathParts.slice(2, 3).join("/");
    const menuToBeSelected = menuconfig.find(menu => {
      return menu.path === menuPath;
    });
    if (menuToBeSelected) {
      menuToBeSelected.children.find((child, index) => {
        return child.path === tabPath;
      });
      const allowedMenus = menuconfig.filter(menu => {
        return menu.canAllow;
      });
      if (tabPath === '') {
        let isFirst = true;
        menuToBeSelected.children.map((child, index) => {
          if (child.canAllow && isFirst) {
            isFirst = false;
          }
        })
      }
      this.setState({
        selectedMenuKey: menuToBeSelected.key,
        menuItems: allowedMenus
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configurations !== this.props.configurations) {
      if (this.props.location.pathname !== "/appv2/") {
        this.selectMenuAndTabForValidUrl();
      }
    }
  }

  getUrlDirection = (path, type) => {
    if (type) {
      this.props.history.push(`/appv2/${path}/dashboard`);
    } else {
      window.location.replace(`${angularUrl}${path}/dashboard`);
    }
  }

  getMenuHtml = (data) => {
    return data.map((item, i) => {
      let redirectUrl;
      redirectUrl = item.isReact ? (reactUrl + "/appv2/" + item.path + "/dashboard") : (angularUrl + item.path + "/dashboard");
      let newTab = false;
      let isSelected = false;
      if (item.type === 'external-link') {
        newTab = true;
      }
      isSelected = (item.key === this.state.selectedMenuKey);
      let classes = ['menu-item-container'];
      let aHtml = null;
      if (item.isReact) {
        aHtml = <a className="menu-item-label" onClick={() => this.getUrlDirection(item.path, 'react')}  target={newTab ? '_blank' : '_self'}>{item.label}</a>;
      }
      // let aHtml = <a className="menu-item-label" href={redirectUrl} target={newTab ? '_blank' : '_self'}>{item.label}</a>;
      if (isSelected) {
        classes.push('menu-item-container-active');
        aHtml = <span className="menu-item-label">{item.label}</span>
      }
      return (<Menu.Item
        key={`${item.path}${i}`}
        className={classes.join(' ')}>
        <Icon img={isSelected ? item.activeIcon : item.inactiveIcon} className="menu-item-img" />
        {aHtml}
      </Menu.Item>)
    });
  }

  render() {
    const { menuItems } = this.state;
    let menuItemHtml;
    if (menuItems && menuItems.length > 0) {
      menuItemHtml = this.getMenuHtml(menuItems);
    }

    return (
      <Sider width={240} style={{ background: '#fff' }}
        trigger={null}
        collapsible={this.props.collapse}
        collapsedWidth={0}
        className="sidebar-custom">
        <Menu
          mode="inline"
          defaultSelectedKeys={['pickup']}
          style={{ height: '100%', borderRight: 0 }}
        >
          {menuItemHtml}
        </Menu>
      </Sider>
    );
  }
}

export default Sidebar;