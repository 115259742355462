import React, { Component } from 'react';
import L from 'leaflet';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import Pins from './Pins.js';
import 'leaflet/dist/leaflet.css';

class MapWidget extends Component {
  getBounds = () => {
    const { geojson } = this.props;
    let jsonData = [];
    if(geojson){
      geojson.map(x => {
        let coordinates = [x.latitude, x.longitude];
        jsonData.push(coordinates);
      })
    }
    return jsonData;
  }

  render() {
    return (
      <div className="boxapprox">
      <LeafletMap
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        bounds={this.getBounds()}
        boundsOptions={{padding: [50, 50]}}
      >
        <TileLayer
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
        />
        <Pins {...this.props}/>
      </LeafletMap>
      </div>
    );
  }
}

export default MapWidget;