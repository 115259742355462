import React, { Component } from 'react';
import './live-loads.scss';
import { fetchLiveLoads, fetchLiveLoadsSummary, exportLiveLoads, locationLiveLoads } from './actions.js';
import { connect } from 'react-redux';
import { Card, Row, Col, Input, Form, Select, Table, Empty, DatePicker, Button, Spin, Radio, Tooltip, Icon, Checkbox, Divider, Tag, Modal } from 'antd';
import Sparkline from '../../ui/sparkline/Sparkline';
import moment from 'moment';
import { reportsDownload, checkForDownloadReportStatus } from '../../../common/utils/utils';
import { DownloadTags, Constants } from '../../../common/utils/constants';
import * as appActions from '../../../app/appAction';

import _ from 'lodash';

export const docketStatus = [
  {
    label: 'Pending',
    value: 'IN',
    isActive: true
  },
  {
    label: 'IN_TRANSIT',
    value: 'IN_TRANSIT',
    isActive: true
  },
  // {
  //   label: 'Delivered',
  //   value: 'DELIVERED',
  //   isActive: true
  // }
];

export const filterDocketStatus = [
  // {
  //   label: 'All',
  //   value: 'All',
  //   isActive: true
  // },
  {
    label: 'Pending',
    value: 'IN',
    isActive: true
  },
  {
    label: 'IN_TRANSIT',
    value: 'IN_TRANSIT',
    isActive: true
  },
  // {
  //   label: 'Delivered',
  //   value: 'DELIVERED',
  //   isActive: true
  // }
];

export const deliveryStatus = [
  {
    label: 'None',
    value: 'ALL',
    isActive: true
  },
  {
    label: 'Yes',
    value: 'YES',
    isActive: true
  },
  {
    label: 'No',
    value: 'NO',
    isActive: true
  }
];

class LiveLoads extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isCardExpanded: false,
      showExportDownload: false,
      pageNo: 1,
      allLocations: [],
      allDeliveryLocations: [],
      newFilter: {
        consignmentFilter: {
          "pickupDate": {
            from: moment().subtract(30, 'd').startOf('day').valueOf(),
            to: moment().endOf().valueOf()
          },
          customerPickupLocationIds: [],
          destinationLocationIds: []
        }
      },
      selectedAll: true,
      loading: false,
      startDate: moment().subtract(30, 'd').startOf('day'),
      endDate: moment().endOf('day'),
      docketStatuses: ['IN', 'IN_TRANSIT'],
      deliveryBreach: 'ALL',
      searchValue: 'docket',
      searchByValue: '',
      searchedValue: [],
      isEndDateOpen: false,
      filterPayload: {
        filters: {
          "pageNo": 1,
          "pageSize": 10,
          "consignmentFilter": {
            "statuses": [
              "IN",
              "IN_TRANSIT",
            ],
            "pickupDate": {
              from: moment().subtract(30, 'd').startOf('day').valueOf(),
              to: moment().endOf().valueOf()
            },
            "entityTypes": [
              "NONE",
              "MANIFEST"
            ],
            "customerIds": [Constants.sessionUser.location.entityId],
            "partnerIds": [Constants.PARTNER_ID],
          }
        }
      },
      columns: [
        {
          title: 'Docket No.',
          dataIndex: 'waybillNo',
          key: 'waybillNo',
          fixed: 'left',
          width: 150,
          render: (data, row) => (<span style={{ color: '#2c5de5' }}><a className="docket-link" onClick={() => this.gotoTrackFrom(row, 'docket')}>{data}</a></span>)
        },
        {
          title: 'Origin',
          dataIndex: 'originLocation',
          key: 'originLocation',
          width: 130,
          render: data => (<span className="bold">{data}</span>)
        },
        {
          title: 'Destination',
          dataIndex: 'destinationLocation',
          key: 'destinationLocation',
          width: 110,
          render: data => (<span className="bold">{data}</span>)
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoiceNumber',
          key: 'invoiceNumber',
          render: (data, row) => (<span>{data}</span>)
        },
        {
          title: 'No. of Pieces',
          dataIndex: 'shipmentCount',
          key: 'shipmentCount',
        },
        {
          title: 'Weight (Kgs)',
          dataIndex: 'weight',
          key: 'weight',
          render: data => (<span className="bold">{data}</span>)
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          width: 130,
          render: data => <div className={`status-${data}`}>{data}</div>
        },
        {
          title: 'Breach Level',
          dataIndex: 'isBreached',
          key: 'isBreached',
          align: 'center',
          width: 120,
          render: (data, record) => {
            return <>
              {data ?
                <div>
                  {/* {data} */}
                  <div>
                    {record?.breachedLevel &&
                      record?.breachedLevel === 'High' ?
                      <Tooltip title={'breach hours > 3'}>
                        <Tag color="#fe6769">{record?.breachedLevel}</Tag>
                      </Tooltip> :
                      (record?.breachedLevel === 'Low' ?
                        <Tooltip title={'breach hours < 1'}>
                          <Tag color="#b6deb5">{record?.breachedLevel}</Tag>
                        </Tooltip>
                        : record?.breachedLevel === 'Moderate' ?
                          <Tooltip title={'breach hours between 1 - 3'}>
                            <Tag color="#ffd65c">{record?.breachedLevel}</Tag>
                          </Tooltip> :
                          record?.breachedLevel)
                    }
                  </div>

                </div>
                : <></>
              }


            </>

          }
        },
        {
          title: 'Booked On',
          dataIndex: 'pickupDate',
          key: 'pickupDate',
          width: 110,
          render: data => data ? <div> {moment(data).format('DD/MM/YYYY hh:mm A')} </div> : <></>
        },
        {
          title: 'Delivery Promised On',
          dataIndex: 'customerPromiseDate',
          key: 'customerPromiseDate',
          width: 130,
          render: data => data ? (<span className="bold">{moment(data).format('DD/MM/YYYY hh:mm A')}</span>) : <></>
        },
        {
          title: 'Action',
          dataIndex: 'docketNo.',
          key: 'docketNo',
          fixed: 'right',
          render: (data, row) => {
            return (
              <Button type='primary' onClick={() => this.gotoTrack(row)}>Track</Button>
            )
          }
        }
      ]
    }

    this.fetchLocation();
    if (Constants.sessionUser.userLevel === 'CUSTOMER_SUPER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_USER') {
      this.applyPagination({
        pageNo: 1, consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          customerPickupLocationIds: []
        }
      }, true);
    }
  }



  componentDidUpdate(prevProps) {
    if (this.props.locationList !== prevProps.locationList) {
      const level = Constants.sessionUser.userLevel;
      this.setState({
        filterPayload: {
          ...this.state.filterPayload,
          filters: {
            ...this.state.filterPayload.filters,
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              customerPickupLocationIds: (level === 'CUSTOMER_SUPER_ADMIN' || level === 'CUSTOMER_ADMIN' || level === 'CUSTOMER_USER') ? [] : this.props?.locationList?.PICKUP?.map(item => (item.id))
            }
          }
        }
      })

      if (this.props.locationList && this.state.allLocations.length <= 0) {
        let allLocations = this.props.locationList && this.props?.locationList['PICKUP']?.map(list => {
          return {
            id: list.id,
            name: list.name,
            selected: false
          }
        });

        this.setState({ allLocations })
      }

      if (this.props.locationList && this.state.allDeliveryLocations.length <= 0) {
        let allDeliveryLocations = this.props.locationList && this.props?.locationList['DELIVERY']?.map(list => {
          return {
            id: list.id,
            name: list.name,
            selected: false
          }
        });

        this.setState({ allDeliveryLocations })
      }
      if (!(Constants.sessionUser.userLevel === 'CUSTOMER_SUPER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_ADMIN' || Constants.sessionUser.userLevel === 'CUSTOMER_USER')) {
        this.applyPagination({
          pageNo: 1, consignmentFilter: {
            ...this.state.filterPayload.filters.consignmentFilter,
            customerPickupLocationIds: this.props.locationList?.PICKUP?.map(item => (item.id))
          }
        }, true);
      }
    }
    if ((this.props.downloadStatus === "SUCCESS" || this.props.downloadStatus === "FAILURE") && this.state.loading) {
      this.setState({ loading: false, showExportDownload: false })
    }
    if (this.props.exportData !== prevProps.exportData) {
      if (this.props?.exportData?.url) {
        const prop = {
          setS3SyncStatus: this.props.setS3SyncStatus,
          uploadedFileResp: {
            responseUrl: this.props.exportData.url
          }
        }
        checkForDownloadReportStatus(prop, "Downloaded Successfully", "Success");
        // reportsDownload(this.props.exportData.url, DownloadTags.s3)
      }
    }
  }

  gotoTrack = (row) => {
    
    this.props.history.push('/appv2/customer-tracking/dashboard', { waybillNo: row.waybillNo, searchedBy: this.state.searchValue })
  }

  gotoTrackFrom = (row, searchBy) => {
    
    if (searchBy == 'docket') {
      this.props.history.push('/appv2/customer-tracking/dashboard', { waybillNo: row.waybillNo, searchedBy: searchBy })
    } else {
      this.props.history.push('/appv2/customer-tracking/dashboard', { invoiceNumber: row.invoiceNumber, searchedBy: searchBy })
    }

  }

  fetchLocation = () => {
    // const locationPayload = {
    //   "filters": {
    //     "entityTypes": [
    //       "CUSTOMER"
    //     ],
    //     "entityId": [Constants.sessionUser.location.entityId],
    //     "status": true,
    //     "roles": ["PICKUP", "DELIVERY"]
    //   }
    // };

    let level = Constants.sessionUser.userLevel;
    let locationPayload = {};
    if (level === 'CUSTOMER_SUPER_ADMIN' || level === 'CUSTOMER_ADMIN') {
      locationPayload = {
        "filters": {
          "deviceFilter": {
            "view": "LOCATION_LIST_ERP"
          },
          // "linkedUsers": [Constants.sessionUser.id],
          "status": true,
          "roles": ["PICKUP", "DELIVERY"],
          "isConsiderCreatorId": false,
          "entityTypes": [
            "CUSTOMER"
          ],
          "entityIds": [Constants.sessionUser.location.entityId],
          "deviceType": "DESKTOP",
          fetchObjects: []
        }
      }
    } else {
      locationPayload = {
        "filters": {
          "deviceFilter": {
            "view": "LOCATION_LIST_ERP"
          },
          "linkedUsers": [Constants.sessionUser.id],
          "status": true,
          "roles": ["PICKUP", "DELIVERY"],
          "isConsiderCreatorId": false,
          "entityTypes": [
            "CUSTOMER"
          ],
          "entityIds": [Constants.sessionUser.location.entityId],
          "deviceType": "DESKTOP",
          fetchObjects: []
        }
      }
    }

    this.props.locationLiveLoads(locationPayload);
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }

    let data = {};

    if (_.isEmpty(filters)) {
      data = { filters: { ...this.state.filterPayload.filters } };
    } else {
      data = { filters: { ...this.state.filterPayload.filters, ...filters } };
    }
    
    // data = { filters: { ...this.state.filterPayload.filters, ...filters } };
    this.props.fetchLiveLoads(data, isReset);
    this.props.fetchLiveLoadsSummary({ filters: data.filters });
  }

  reloadCustomerList = () => {
    this.setState({
      pageNo: 1
    })
    this.InputSearchRef.current.input.state.value = '';
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo) => {
    const { PaginatedData, } = this.props;
    if (!PaginatedData ? !PaginatedData : !PaginatedData[pageNo]) {
      this.applyPagination({
        pageNo,
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
        }
      })
    }
    this.setState({
      pageNo
    });
  }

  onChange = (e) => {
    if (e.target.checked) {
      this.setState({ docketStatus: { statuses: ["All", "IN", "IN_TRANSIT"] }, selectedAll: e.target.checked });
      this.handleDocketStatusChange(["All", "IN", "IN_TRANSIT"])
    } else {
      this.setState({ docketStatus: { statuses: [] } });
      this.handleDocketStatusChange([])
    }
  }



  handleDocketStatusChange = val => {
    if (val.length <= 0 || val.indexOf('All') >= 0) {
      val = ["IN", "IN_TRANSIT"]
    } else {
      this.setState({ selectedAll: false })
    }

    this.setState({ docketStatuses: val });

    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.filters.consignmentFilter,
        ...this.state.newFilter.consignmentFilter,
        statuses: val
      }
    }
    this.setState({
      newFilter: payload
    }, this.applyPagination({ ...payload }, true))
  }

  handleDeliveryStatusChange = val => {
    const payload = {
      consignmentFilter: {
        ...this.state.filterPayload.filters.consignmentFilter,
        ...this.state.newFilter.consignmentFilter
      }
    };
    if (val === 'YES') {
      payload.consignmentFilter.isBreached = true
    } else if (val === 'NO') {
      payload.consignmentFilter.isBreached = false
    } else {
      delete payload.consignmentFilter.isBreached;
    }
    const ValueSet = {
      filterPayload: {
        ...this.state.filterPayload,
        filters: {
          ...this.state.filterPayload.filters,
          ...payload
        }
      }
    }
    if (val === 'ALL') {
      delete ValueSet.filterPayload.filters.consignmentFilter.isBreached
    }



    this.setState({
      ...ValueSet,
      deliveryBreach: val
    }, this.applyPagination({ ...payload }, true))
  }

  handleSearch = val => {
    let filter = {};
    if (val === this.state.searchedValue.join(',')) return false
    if (!val) {
      val = []
    } else
      val = val.split(',').map(v => (v.trim()))
    if (this.state.searchValue == 'docket') {
      filter = {
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          waybills: val
        }
      }
    } else {
      filter = {
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter.consignmentFilter,
          invoiceNumbers: val
        }
      }
    }


    if (val.length === 0) {
      delete filter.consignmentFilter.waybills;
      delete filter.consignmentFilter.invoiceNumbers;
    }

    this.setState({ newFilter: filter, searchedValue: val });
    this.applyPagination(filter, true)
  }

  exportData = () => {
    this.props.setS3SyncStatusReset();
    
    this.setState({ loading: true, showExportDownload: true })
    let { consignmentFilter } = this.state.newFilter;


    // if (_.isEmpty(consignmentFilter)) {
    //   consignmentFilter = {...this.state.filterPayload.filters.consignmentFilter, ...this.state.newFilter.consignmentFilter,};
    // }

    this.props.exportLiveLoads({
      consignmentFilter: {
        ...this.state.filterPayload.filters.consignmentFilter,
        ...consignmentFilter
      }
    })
  }

  disabledStartDate = startValue => {
    const { startDate } = this.state;
    if (!startValue || !startDate) {
      return false;
    }

    return startValue.valueOf() && startValue.valueOf() > moment().endOf('day');
    // return startValue.valueOf() > startDate.valueOf();
  };

  disabledEndDate = endValue => {
    if (this.state.startDate) {
      const dayStart = moment(this.state.startDate);
      const dayEnd = moment(this.state.startDate).add(31, 'days');
      if (dayEnd.isAfter(moment().endOf('day'))) {

        return !(dayStart.isSameOrBefore(endValue) && endValue <= moment().endOf('day') && endValue >= moment(this.state.startDate).endOf('day'));
      } else {

        return !(dayStart.isSameOrBefore(endValue) && dayEnd.isAfter(endValue) && endValue.isSameOrBefore(dayEnd));
      }


    } else {
      // return current && current > moment().endOf('day');
    }
  }

  disabledDate = current => {

    if (this.state.startDate) {
      const dayStart = moment(this.state.startDate).add(-31, 'days');
      const dayEnd = moment(this.state.startDate).add(31, 'days');
      if (dayEnd.isAfter(moment().endOf('day'))) {
        return !(dayStart.isSameOrBefore(current) && current < moment().endOf('day'));
      } else {
        return !(dayStart.isSameOrBefore(current) && dayEnd.isAfter(current));
      }


    } else {
      // return current && current > moment().endOf('day');
    }

  }

  handleFilterSet = (key, value, field, fieldValue) => {

    if (key == 'customerPickupLocationIds' && value == 'All') {

      let level = Constants.sessionUser.userLevel;
      if (level === 'CUSTOMER_SUPER_ADMIN') {
        this.setState({
          newFilter: {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
              [key]: []
            }
          }
        });
      } else {
        let locations = this.props.locationList['PICKUP'].map(list => {
          return list.id;
        });
        this.setState({
          newFilter: {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
              [key]: locations
            }
          }
        });

      }
    } else {
      if (key === 'destinationLocationIds') {
        if (value.length > 0) {
          let selectedValues = this.state.allDeliveryLocations.map(list => {
            if (value.includes(list.id)) {
              list = {
                ...list,
                selected: true
              }

              return list;
            } else {
              list = {
                ...list,
                selected: false
              }
              return list
            }
          });
          var sortedLocations = selectedValues.sort((a, b) => {
            return b.selected - a.selected;
          });

          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            },
            allDeliveryLocations: [...sortedLocations]
          });

        } else {
          let { newFilter } = this.state;
          let { consignmentFilter } = newFilter;
          if (_.hasIn(consignmentFilter, 'destinationLocationIds')) {
            delete consignmentFilter['destinationLocationIds'];

          }

          newFilter = {
            consignmentFilter: {
              ...this.state.filterPayload.filters.consignmentFilter,
              ...this.state.newFilter.consignmentFilter,
            }
          }

          this.setState({ newFilter })


        }

      } else {
        if (key == 'customerPickupLocationIds') {
          let selectedValues = this.state.allLocations.map(list => {
            if (value.includes(list.id)) {
              list = {
                ...list,
                selected: true
              }

              return list;
            } else {
              list = {
                ...list,
                selected: false
              }
              return list
            }
          });
          var sortedLocations = selectedValues.sort((a, b) => {
            return b.selected - a.selected;
          });

          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            },
            allLocations: [...sortedLocations]
          });
        } else {
          this.setState({
            newFilter: {
              consignmentFilter: {
                ...this.state.filterPayload.filters.consignmentFilter,
                ...this.state.newFilter.consignmentFilter,
                [key]: value
              }
            }
          });
        }
      }

    }

    if (field && fieldValue) {
      this.onChangeDate(field, fieldValue)
    }

  }

  onChangeDate = (field, value) => {
    // console.log("startDate->>", startDate)
    // let { startDate } = this.state;
    // startDate = startDate.valueOf();
    // console.log("startDate->>", startDate)
    this.setState({ [field]: value, })
  }

  onEndDateChange = (endDate) => {

    // let { endDate } = this.state;
    // endDate = endDate.valueOf();

    this.setState({ endDate })
  }

  onDateChange = (date, dateString) => {

    if (date[0]) {
      this.setState({ startDate: date[0], endDate: date[1] })
    }
  }

  onChangeSearch = e => {
    this.setState({ searchValue: e.target.value })
  }

  changeSearch = e => {
    this.setState({ searchByValue: e.target.value })
  }

  applyfilters = () => {
    this.setState({
      docketStatuses: ['IN', 'IN_TRANSIT'],
      searchValue: 'docket',
      searchByValue: '',
      searchedValue: [],
      deliveryBreach: 'ALL'
    })
    const { filterPayload, newFilter } = this.state;
    delete filterPayload.filters.consignmentFilter.isBreached
    delete newFilter.consignmentFilter.isBreached
    delete filterPayload.filters.consignmentFilter.waybills
    delete newFilter.consignmentFilter.waybills
    delete filterPayload.filters.consignmentFilter.invoiceNumbers
    delete newFilter.consignmentFilter.invoiceNumbers
    this.applyPagination({
      consignmentFilter: {
        ...filterPayload.filters.consignmentFilter,
        ...newFilter.consignmentFilter,
        "statuses": [
          "IN",
          "IN_TRANSIT",
        ],

      }
    }, true)
  }

  resetFilters = e => {
    this.setState({
      docketStatuses: ['IN', 'IN_TRANSIT'],
      searchValue: 'docket',
      startDate: moment().subtract(30, 'd').startOf('day'),
      endDate: moment().endOf('day'),
      searchByValue: '',
      searchedValue: [],
      deliveryBreach: 'ALL',
      newFilter: {
        consignmentFilter: {
          ...this.state.filterPayload.filters.consignmentFilter,
          ...this.state.newFilter,
          "pickupDate": {
            from: moment().subtract(30, 'd').startOf('day').valueOf(),
            to: moment().endOf().valueOf()
          },
          customerPickupLocationIds: [],
          destinationLocationIds: [],
          statuses: ['IN', 'IN_TRANSIT']
        }
      }
    }, () => {
      this.applyPagination({ ...this.state.newFilter }, true)
    })
  }

  render() {
    const { columns, pageNo, isEndDateOpen, isCardExpanded, newFilter, startDate, endDate, filterPayload, allLocations } = this.state;
    const { PaginatedData, liveLoadsData, locationList, summaryList } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      labelAlign: "left",
      colon: false
    };
    return (
      <div className="LiveLoads">
        <Card>
          {
            isCardExpanded ? (
              <>
                {/* <Tooltip placement="top" title={"Colapase Filters"}>
                <Button style={{ float: 'right', zIndex: 5 }} onClick={e => this.setState({ isCardExpanded: !isCardExpanded })}> <Icon type={'shrink'} /> </Button>
              </Tooltip> */}
                <Form {...formItemLayout}>
                  <Form.Item colon={false} label="Booking Date" wrapperCol={{ span: 12 }}>
                    <Input.Group style={{ display: 'inline' }}>
                      {/* <DatePicker.RangePicker onCalendarChange={this.onDateChange} disabledDate={this.disabledDate} value={[startDate, endDate]} onChange={value => this.handleFilterSet('bookingDate', { from: value[0].valueOf(), to: value[1].valueOf() })} /> */}
                      <Row gutter={12}>

                        <Col span={12} style={{ bottom: 5 }}>
                          <span className="form-label-text">From </span>
                          <DatePicker
                            disabledDate={this.disabledStartDate}

                            format="YYYY-MM-DD"
                            value={startDate}
                            placeholder="From"
                            style={{ width: '100%' }}
                            // onOk={this.onStartDateChange}
                            onChange={value => { this.handleFilterSet('pickupDate', { from: value.valueOf(), to: endDate.valueOf() }, 'startDate', value) }}
                            onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }, () => { this.setState({ endDate: moment(this.state.startDate).endOf('day') }) }) : {}}
                          // onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true, endDate: moment(startDate).endOf('day') }) : {}}
                          // onOpenChange={this.handleStartOpenChange}
                          />
                        </Col>

                        <Col span={12} style={{ bottom: 5 }}>
                          <span className="form-label-text">To </span>
                          <DatePicker
                            disabledDate={this.disabledEndDate}
                            format="YYYY-MM-DD"
                            value={endDate}
                            placeholder="To"
                            style={{ width: '100%' }}
                            // onOk={this.onEndDateChange}
                            open={isEndDateOpen}
                            onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}
                            onChange={value => {

                              this.handleFilterSet('pickupDate', { from: startDate.valueOf(), to: value.valueOf() }, 'endDate', value)
                            }
                            }

                          // onOpenChange={this.handleStartOpenChange}
                          />
                        </Col>
                      </Row>


                      {/* <span style={{ marginLeft: '5px' }}></span>
                    <DatePicker onChange={this.onChange} /> */}
                    </Input.Group>
                  </Form.Item>
                  <Form.Item colon={false} label="Origin (Pickup)" wrapperCol={{ span: 6 }}>
                    <Select placeholder="All Locations" mode='multiple' value={newFilter.consignmentFilter?.customerPickupLocationIds} onChange={value => this.handleFilterSet('customerPickupLocationIds', value)} style={{ width: '98%' }} showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {/* <Select.Option value={'All'}>All Location</Select.Option> */}
                      {
                        this.state.allLocations && this.state.allLocations.map(list => (
                          <Select.Option value={list.id}>
                            <Tooltip title={list.name}>
                              {list.name}
                            </Tooltip>

                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item colon={false} label="Destination" wrapperCol={{ span: 6 }}>
                    <Select placeholder="All Location" mode='multiple' value={newFilter.consignmentFilter?.destinationLocationIds} onChange={value => this.handleFilterSet('destinationLocationIds', value)} style={{ width: '98%' }} showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        this.state.allDeliveryLocations && this.state.allDeliveryLocations.map(list => (
                          <Select.Option value={list.id}>
                            <Tooltip title={list.name}>
                              {list.name}
                            </Tooltip>
                          </Select.Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                  <Row>
                    <Col span={3} offset={4}>
                      <Button onClick={e => this.applyfilters()} type="primary">Apply Filters</Button>
                    </Col>
                    <Col span={3}>
                      <Button onClick={e => this.resetFilters(e)} style={{ width: 104, backgroundColor: '#f2f2f5' }}>Clear</Button>
                    </Col>
                    <Col span={3} offset={10}>
                      <div style={{ position: 'relative', left: 25 }}>
                        <a style={{ float: 'right', zIndex: 5, color: '#1e6ee3' }} onClick={e => this.setState({ isCardExpanded: !isCardExpanded })}> Show Less </a>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </>
            )
              : (
                <Row>
                  <Col span={2}>
                    <Form.Item colon={false} label="Booking From">
                      {/* <DatePicker.RangePicker onCalendarChange={this.onDateChange} disabledDate={this.disabledDate} value={[startDate, endDate]} onChange={value => this.handleFilterSet('bookingDate', { from: value[0].valueOf(), to: value[1].valueOf() })} /> */}

                      <DatePicker
                        disabledDate={this.disabledStartDate}
                        format="YYYY-MM-DD"
                        value={startDate}
                        placeholder="From"
                        style={{ width: "150px" }}
                        // onOk={this.onStartDateChange}
                        onChange={value => { this.handleFilterSet('pickupDate', { from: value.valueOf(), to: endDate.valueOf() }, 'startDate', value) }}
                        // onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true, endDate: moment(startDate).endOf('day') }) : {}}
                        onOpenChange={isOpen => !isOpen ? this.setState({ isEndDateOpen: true }, () => { this.setState({ endDate: moment(this.state.startDate).endOf('day') }) }) : {}}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} offset={2}>
                    <Form.Item colon={false} label="Booking To">
                      <DatePicker
                        disabledDate={this.disabledEndDate}
                        format="YYYY-MM-DD"
                        value={endDate}
                        placeholder="To"

                        style={{ width: "150px" }}
                        open={isEndDateOpen}
                        // onOk={this.onEndDateChange}
                        onChange={value => {

                          this.handleFilterSet('pickupDate', { from: startDate.valueOf(), to: value.valueOf() }, 'endDate', value)
                        }
                        }
                        onOpenChange={isOpen => this.setState({ isEndDateOpen: isOpen })}

                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} offset={2}>
                    <Form.Item colon={false} label="Origin (Pickup)">
                      <Select style={{ width: 150 }} placeholder="All Location" mode='multiple' value={newFilter.consignmentFilter?.customerPickupLocationIds} onChange={value => this.handleFilterSet('customerPickupLocationIds', value)} maxTagCount="1" showSearch maxTagTextLength="5"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {/* <Select.Option value={'All'}>All Location</Select.Option> */}
                        {
                          this.state.allLocations && this.state.allLocations.map(list => (
                            <Select.Option value={list.id}>
                              <Tooltip title={list.name}>
                                {list.name}
                              </Tooltip>
                            </Select.Option>
                          ))
                        }
                      </Select>
                      {/* <Input readOnly style={{ width: "150px" }} disabled value="All Location" suffix={<>{newFilter.consignmentFilter?.customerPickupLocationIds?.length > 0 ? <Tag>{newFilter.consignmentFilter?.customerPickupLocationIds?.length}</Tag> : <></>}</>} /> */}
                    </Form.Item>
                  </Col>
                  <Col span={2} offset={2}>
                    <Form.Item colon={false} label="Destinations">
                      <Select style={{ width: 150 }} mode='multiple' placeholder="All Location" value={newFilter.consignmentFilter?.destinationLocationIds} onChange={value => this.handleFilterSet('destinationLocationIds', value)} maxTagCount="1" showSearch maxTagTextLength="5"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {
                          this.state.allDeliveryLocations && this.state.allDeliveryLocations.map(list => (
                            <Select.Option value={list.id}>
                              <Tooltip title={list.name}>
                                {list.name}
                              </Tooltip>
                            </Select.Option>
                          ))
                        }
                      </Select>
                      {/* <Input readOnly style={{ width: "150px" }} disabled value="All Location" suffix={<> {newFilter.consignmentFilter?.destinationLocationIds?.length ? <Tag>{newFilter.consignmentFilter?.destinationLocationIds?.length}</Tag> : <></>}</>} /> */}

                    </Form.Item>
                  </Col>
                  {/* <Col span={5}>
                    <Button onClick={e => this.applyPagination({ ...newFilter }, true)} type="primary">Apply Filters</Button>
                  </Col> */}
                  <Col span={1} push={2}>
                    <Form.Item colon={false} label="&nbsp;">
                      <Button style={{ marginLeft: 5 }} onClick={e => this.applyfilters()} type="primary">Apply Filters</Button>

                    </Form.Item>
                  </Col>
                  <Col span={2} push={3}>
                    <Form.Item colon={false} label="&nbsp;">
                      <Button onClick={e => this.resetFilters(e)} style={{ width: 104, backgroundColor: '#f2f2f5', marginLeft: 50 }}>Clear</Button>
                    </Form.Item>
                  </Col>
                  <Col span={4} push={4}>
                    <Form.Item colon={false} label="&nbsp;">
                      {/* <Row gutter={24}>
                      <Col span={16}>
                        <Button block onClick={e => this.applyPagination({ consignmentFilter:{...filterPayload.filters.consignmentFilter,...newFilter.consignmentFilter} }, true)} type="primary">Apply Filters</Button>
                      </Col>
                      <Col span={2}>
                        <Tooltip placement="top" title={"Expand Filters"}>
                          <Button onClick={e => this.setState({ isCardExpanded: true })}><Icon type="arrows-alt" /></Button>
                        </Tooltip>
                      </Col>
                    </Row> */}
                      <a onClick={e => this.setState({ isCardExpanded: true })} style={{ color: '#1e6ee3', marginLeft: 50 }}>Show More</a>
                    </Form.Item>
                  </Col>

                </Row>
              )
          }
        </Card>

        <Card className="margin-10">
          <Row gutter={24}>
            <Col span={12}>
              {/* <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Search By: ">

              </Form.Item> */}

              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="Search By">
                <Row>
                  <Col span={14}>
                    <Radio.Group onChange={this.onChangeSearch} value={this.state.searchValue}>
                      <Radio value={"docket"}>Docket No.</Radio>
                      <Radio value={"invoice"}>Invoice No.</Radio>
                    </Radio.Group>
                  </Col>
                  <Col span={10} pull={4}>
                    <Input.Search value={this.state.searchByValue} onChange={this.changeSearch} placeholder={`Search by ${this.state.searchValue} no.`} onSearch={this.handleSearch} enterButton allowClear />
                  </Col>
                </Row>


              </Form.Item>
            </Col>
            <Col span={4} pull={1}>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ marginTop: -5 }} label="Delivery Breach">
                <Select style={{ width: '100%' }} value={this.state.deliveryBreach} maxTagCount="1" onChange={this.handleDeliveryStatusChange}>
                  {
                    deliveryStatus.filter(s => s.isActive).map(status => (
                      <Select.Option value={status.value}>{status.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={5} pull={1}>
              <Form.Item labelCol={{ span: 24 }} showArrow wrapperCol={{ span: 24 }} style={{ marginTop: -5 }} label="Docket Status">
                {/* <Select value={this.state.filterPayload.filters.consignmentFilter.statuses} style={{ width: '100%' }} onChange={this.handleDocketStatusChange} mode="multiple" maxTagCount="1" maxTagTextLength={<>{this.state.filterPayload.filters.consignmentFilter.statuses.length}</>}>
                  {
                    filterDocketStatus.filter(s => s.isActive).map(status => (
                      <Select.Option value={status.value}>{status.label}</Select.Option>
                    ))
                  }
                </Select> */}
                <Select value={this.state.docketStatuses} style={{ width: '240px' }} showArrow onChange={this.handleDocketStatusChange} mode="multiple"
                  dropdownRender={menu => (
                    <div
                    >
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div onMouseDown={e => e.preventDefault()}>
                        <Checkbox onClick={this.onChange} onChange={this.onChange} checked={this.state.selectedAll} defaultChecked>Select All</Checkbox>

                      </div>

                    </div>
                  )}
                >
                  {
                    filterDocketStatus.filter(s => s.isActive).map(status => (
                      <Select.Option value={status.value}>{status.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={2} style={{ left: 5 }}>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="&nbsp;">
                <Button style={{ marginLeft: 6, bottom: 5 }} disabled={liveLoadsData.totalCount == 0} onClick={this.exportData} ><Icon type="download" />Export</Button>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Spin spinning={liveLoadsData?.summaryLoading}>
                <Sparkline
                  title="Pending"
                  highlightText={<span style={{ color: '#e86825' }}> {summaryList?.length ? summaryList.filter(item => item.status === 'PENDING')[0]?.count : '0'} </span>}
                />
              </Spin>
            </Col>
            <Col span={6}>
              <Spin spinning={liveLoadsData?.summaryLoading}>
                <Sparkline
                  title="Intransit"
                  highlightText={<span style={{ color: '#2c5de5' }}>{summaryList?.length ? summaryList.filter(item => item.status === 'IN_TRANSIT')[0]?.count : '0'}</span>}
                />
              </Spin>
            </Col>
            <Col span={16}>
              {/* <Spin spinning={liveLoadsData?.loading}>
                <Sparkline
                  title="OFD ( Out For Delivery )"
                  highlightText={<span style={{ color: '#008556' }}>0</span>}
                />
              </Spin> */}
            </Col>
            <Col span={6}>
              {/* <Spin spinning={liveLoadsData?.loading}>
                <Sparkline
                  title="Exp. Delivery Compliance"
                  highlightText={'100%'}
                  infoText="Exp. Delivery Compliance"
                />
              </Spin> */}
            </Col>
            <Col span={24} className="margin-10">
              <Table
                bordered
                locale={{
                  emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }}
                loading={liveLoadsData?.liveLoading}
                pagination={{
                  onChange: this.getPaginatedData,
                  total: liveLoadsData?.totalCount,
                  position: 'bottom',
                  current: pageNo,
                  pageSize: liveLoadsData?.pageSize
                }}
                scroll={{ x: 1250 }}
                dataSource={PaginatedData ? PaginatedData[pageNo] : []}
                columns={columns} />
            </Col>
          </Row>
          <Modal
            title={false}
            visible={this.state.showExportDownload}
            centered
            maskClosable={false}
            closable={false}
            footer={false}
          >
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" tip={<span style={{ color: 'black' }}>Download In Progress</span>} />
            </div>

          </Modal>
        </Card>
      </div >
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  liveLoadsData: state.liveLoads,
  liveLoading: state.liveLoads?.liveLoading,
  summaryLoading: state?.liveLoads?.summaryLoading,
  PaginatedData: state.liveLoads?.PaginatedData,
  exportData: state.liveLoads?.export,
  locationList: state.liveLoads?.location,
  summaryList: state.liveLoads?.consignmentSummaryCountBOs,
  downloadStatus: state?.app?.s3Status
});
const mapDispatchToProps = dispatch => ({
  fetchLiveLoads: (payload, isReset) => dispatch(fetchLiveLoads(payload, isReset)),
  fetchLiveLoadsSummary: payload => dispatch(fetchLiveLoadsSummary(payload)),
  exportLiveLoads: payload => dispatch(exportLiveLoads(payload)),
  locationLiveLoads: (key, payload) => dispatch(locationLiveLoads(key, payload)),
  setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
  setS3SyncStatusReset: () => dispatch(appActions.setS3SyncStatusReset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LiveLoads);