import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './zoneDashboard.scss';
import { Card, Button, Input, Row, Col, Modal, Form, Divider, Spin, Icon, Select, Steps } from 'antd';
import { canAllow, windowRedirect, notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import { getZone, createZone, updateZone, fetchZones, getAllRoutes, resetZone } from './actions';
// import { fetchCustomers, getVendors } from "../../settings/location/actions";
import ZoneMapping from '../zone-mapping/ZoneMapping';

import moment from 'moment';
import { Constants } from '../../../common/utils/constants';

const { Option } = Select;
const { Step } = Steps;
class ZoneForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            disabledRoute: true,
            disableForm: false,
            current: 0,
            updateDisabled: true,
            showNextStep: false,
            filterPayload: {
                filters: {
                    pageNo: 1,
                    pageSize: 10,
                    locationSpecific: false,
                    fetchObjects: [
                        'ZONE_MAPPING'
                    ],
                    isActive: true
                }
            },
            zonePermission: {
                read: canAllow(permissionTags.settings.zone.read),
                update: canAllow(permissionTags.settings.zone.update),
                create: canAllow(permissionTags.settings.zone.create),
                delete: canAllow(permissionTags.settings.zone.delete),
                view: canAllow(permissionTags.settings.zone.view),
            },
            zoneId: props.location?.state?.id,
            isEdit: !(props?.location?.state?.id > 0),
            selectedAreas: [],
            selectedPincodes: [],
            isZoneSet: false,
            isSubmitDisabled: false,
            isEntitySet: false
        }
        if (!this.state.zonePermission.read) {
            windowRedirect(`${props.parent}/dashboard/`);
        }
    }

    componentWillUnmount() {
        console.log("in unmount")
        this.props.resetZone();
    }

    componentDidMount() {
        const user = JSON.parse(atob(localStorage.getItem('user')));
        const loggedPartnerId = localStorage.getItem('loggedPartnerId');
        
        if (!this.state.isEdit) {
            
            this.props.getZone(this.state.zoneId);
            const payload = {
                "filters": {
                    "isActive": true,
                    "fetchObjects": [],
                    "canAllowEmptyFetchObjects": true
                }
            }
            this.props.getRoutes(payload);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.zoneData?.zone  &&  Object.keys(this.props.zoneData?.zone).length > 0 && !this.state.isEntitySet) {
            
            this.entityTypeChange(this.props.zoneData.zone?.entityType);
            this.setState({isEntitySet: true})
        }
       
        // if (this.props.zoneData?.zone !== prevProps.zoneData?.zone && Object.keys(this.props.zoneData?.zone).length > 0 && (this.props.zoneData.zone?.entityType === 'PARTNER' || this.props?.ownerCustomer || this.props?.ownervendor)) {
            
        //     console.log("i am here 2", this.props.zoneData?.zone)
        //     this.props.form.setFieldsValue({
        //         zoneName: this.props.zoneData.zone.name,
        //         entityType: this.props.zoneData.zone?.entityType,
        //         zoneType: this.props?.zoneData?.zone?.zoneType ? this.props?.zoneData?.zone?.zoneType : "OPERATIONAL" ,

        //     }, () => {
        //         this.props.form.setFieldsValue({
        //             entityId: this.props?.zoneData?.zone?.entityId
        //         })

        //     })
        //     this.setState({
        //         isZoneSet: true,
        //         selectedPincodes: this.props.zoneData.zone.zoneMapping.map(zone => (zone.entityId))
        //     })

        // }
        if (this.props.zoneData?.zone && !this.state.isZoneSet && Object.keys(this.props.zoneData?.zone).length > 0 && (this.props.zoneData.zone?.entityType === 'PARTNER' || this.props?.ownerCustomer || this.props?.ownervendor)) {
            
            
            this.props.form.setFieldsValue({
                zoneName: this.props.zoneData.zone.name,
                entityType: this.props.zoneData.zone?.entityType,
                zoneType: this.props?.zoneData?.zone?.zoneType ? this.props?.zoneData?.zone?.zoneType : "OPERATIONAL" ,

            }, () => {
                this.props.form.setFieldsValue({
                    entityId: this.props?.zoneData?.zone?.entityId
                })

            })
            this.setState({
                isZoneSet: true,
                selectedPincodes: this.props.zoneData.zone.zoneMapping.map(zone => (zone.entityId))
            })

        }

        if (this.props.zoneResp !== prevProps.zoneResp) {
            if (!this.props.zoneResp?.error) {
                this.props.fetchZones(this.state.filterPayload, true);
            }
        }
        if (this.props.zoneUpdateResp !== prevProps.zoneUpdateResp) {
            if (this.props.zoneUpdateResp.errorMessage) {
                notifyApiSuccess("Zone Updated Succesfully", "SUCCESS");
                this.props.fetchZones(this.state.filterPayload, true);
            } else {
                notifyApiSuccess("Zone Created Succesfully!!", "SUCCESS")
                this.props.fetchZones(this.state.filterPayload, true);
            }


        }

        if (this.props.paginatedZones !== prevProps.paginatedZones) {
            this.props.history.push(`/appv2/${this.props.history.location.pathname.split('/')[2]}/dashboard/zone`)
        }
    }

    goBack = () => {
        this.props.fetchZones(this.state.filterPayload, true);
        this.props.history.goBack()
    }

    patchAreaMapping = selectedAreas => {


        if (!this.state.isEdit) {
            let len1 = this.props?.zoneData?.zone?.zoneMapping?.length;
            let len2 = 0;
            selectedAreas.map(area => {
                area.cities.map(city => {
                    len2 += city.pincodes.length;
                })

            });

            if (len1 === len2) {
                this.setState({
                    selectedAreas,
                    showNextStep: false
                })
            } else {

                this.setState({
                    selectedAreas,
                    showNextStep: true
                })
            }

        } else {
            this.setState({
                selectedAreas,
                showNextStep: false
            })
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            isSubmitDisabled: true
        })
        const { selectedAreas } = this.state;
        const _this = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            const zone = { ...values };
            if (selectedAreas.length === 0) {
                notifyApiError("Please Selected atleast one pincode.", "ERROR:");
                this.setState({
                    isSubmitDisabled: false
                })
                return
            }
            if (!err) {
                const payload = {
                    zone: {
                        name: zone.zoneName,
                        zoneMapping: this.prepareZoneMapping(),
                        zoneType: zone?.zoneType,
                        entityType: zone?.entityType,
                        entityId: zone?.entityId
                    }
                }
                if (!_this.state.isEdit) {
                    this.setState({ updateDisabled: true })
                    payload.zone.id = _this.state.zoneId;
                    if (this.state.showNextStep) {
                        payload.routeId = values?.route?.key;
                        _this.props.updateZone(_this.state.zoneId, payload, true);
                        

                    } else {
                        

                        _this.props.updateZone(_this.state.zoneId, payload, false);
                    }

                } else {
                    
                    _this.props.createZone(payload);
                }
            } else {
                this.setState({
                    isSubmitDisabled: false
                })
            }
        })
    }

    prepareZoneMapping = () => {
        const { selectedAreas } = this.state;
        const zoneMapping = [];

        selectedAreas.map(state => {
            state.cities.map(city => {
                city.pincodes.map(pin => {
                    zoneMapping.push({
                        "mappingEntityType": "PINCODE",
                        "entityId": pin.id
                    })
                })
            })
        });

        return zoneMapping;
    }

    handleProceed = () => {
        this.setState({ showNextStep: true }, () => {
            this.setState({ disabledRoute: false, disableForm: true, current: this.state.current + 1, updateDisabled: true })
        })

    }

    goBackStep1 = () => {
        this.setState({ disabledRoute: true, disableForm: false, current: this.state.current - 1 });
    }

    changeZoneName = e => {
        if (e.target.value === this.props.zoneData.zone.name) {
            this.setState({ updateDisabled: true })
        } else {
            this.setState({ updateDisabled: false })
        }
    }
    onChangeRoute = value => {
        this.setState({ updateDisabled: false })
    }

    entityTypeChange = (entityType) => {
        console.log("entity", entityType)
        if(this.state.ownerType !== entityType) {
            this.setState({ updateDisabled: false })
        }
        this.props.form.setFieldsValue({ entityId: undefined }, () => {
            if (entityType === 'CUSTOMER') {
                // this.props.fetchCustomers({ view: 'LITE_CUSTOMER_LIST' });
            } else if (entityType === 'VENDOR') {
                // this.props.getVendors();
            } else {

            }
            this.setState({ ownerType: entityType })
        })

    }

    getTypeownerData = (entityType) => {
        
        // console.log("this.props ?.ownerCustomer", this.props?.header?.partnerHeaderDetails?.partners)
        if (entityType === 'CUSTOMER') {
            return this.props?.ownerCustomer;
        } else if (entityType === 'VENDOR') {
            return this.props?.ownervendor;
        } else {
            if (!this.state.isEdit && this.props?.locationById?.entityId === Constants.PARTNER_ID) {
                return this.props?.header?.partnerHeaderDetails?.partners;
            }
            if (!this.state.isEdit && this.props?.locationById?.entityId !== Constants.PARTNER_ID) {
                const partner = this.props?.header?.partnerHeaderDetails?.partners.filter(p => p.linkPartnerId === this.props?.locationById?.entityId);
                // console.log("partner->>", partner)
                if (partner && partner.length > 0) {
                    return [{ id: partner[0]?.linkPartnerBO?.id, name: partner[0]?.linkPartnerBO?.name }];
                }

            }
            return [{ id: Constants.PARTNER_ID, name: 'Self' }];
        }
    }


    render() {
        const { isEdit, selectedPincodes, isZoneSet, zonePermission, isSubmitDisabled, updateDisabled, disableForm, current, showNextStep } = this.state;
        const { form, isZoneLoading, isZoneRespLoading, routes } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };
        let selectedPincodes1 = (isEdit && isZoneSet) ? [] : this.state.selectedPincodes.length > 0 ? this.props.zoneData?.zone?.zoneMapping?.map(zone => {
            return (zone.entityId)
        }) : [];

        let steps = [];


        
        let userOptions;

        

        // if (!this.state.isEdit && steps?.length > 0 && this.props.zoneData.zone?.entityType && (this.props.zoneData.zone?.entityType === 'PARTNER' || this.props?.ownerCustomer || this.props?.ownervendor)) {
        //     if (this.state.ownerType !== null) {
        //         userOptions = this.getTypeownerData(this.state.ownerType)
        //     }
        // } else {
        //     if (this.state.ownerType !== null) {
        //         userOptions = this.getTypeownerData(this.state.ownerType)
        //     }
        // }

        if (this.state.ownerType !== null) {
            userOptions = this.getTypeownerData(this.state.ownerType)
        }
        
        if (showNextStep) {
            steps = [
                {
                    title: 'Edit Zone',
                    content: <>
                        <Col span={12}>
                            <Card className={'highlighter-card zone-card zone-card-edit'}>
                                <Form.Item {...formItemLayout} label="Zone Name">
                                    {getFieldDecorator('zoneName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input zone name!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter Owner name"
                                            onChange={this.changeZoneName}
                                            disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item {...formItemLayout} label="Zone Type">
                                    {getFieldDecorator('zoneType', {
                                        initialValue: 'OPERATIONAL',
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input Zone Type"
                                            }
                                        ],
                                    })(
                                        <Select
                                            disabled
                                            placeholder="Select Zone Type" showSearch filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }

                                        >
                                            {Constants.zoneTypes?.map(locOwn => (
                                                <Select.Option
                                                    key={'owner' + locOwn.name}
                                                    value={locOwn.value}
                                                    label={locOwn.name}
                                                >
                                                    {locOwn.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>


                                <Form.Item {...formItemLayout} label="Owner Type">
                                    {getFieldDecorator('entityType', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Please input Owner Type"
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Owner Type" showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }

                                            onChange={this.entityTypeChange}>
                                            {Constants.locationOwners?.map(locOwn => (
                                                <Select.Option
                                                    key={'owner' + locOwn.name}
                                                    value={locOwn.value}
                                                    label={locOwn.name}
                                                >
                                                    {locOwn.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Owner">
                                    {getFieldDecorator('entityId', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Please input Owner"
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Owner" showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                            showArrow={(!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID) ? false : true}
                                            onChange={this.onUserChange}>
                                            {userOptions?.map(user => (
                                                <Select.Option
                                                    key={'user' + user.name}
                                                    value={user.id}
                                                    label={user.name}
                                                >
                                                    {user.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Divider className="step-divider" />
                                <ZoneMapping style={{ height: 300, overflow: 'scroll', bottom: 5 }} disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm} onSelectedAreaAdd={this.patchAreaMapping} selectedPincodes={selectedPincodes1} />
                            </Card>
                        </Col>


                    </>
                },
                {
                    title: 'Select Route',
                    content: <>
                        <Col span={12}>
                            <Card className="zone-card zone-card-edit">
                                <Form.Item {...formItemLayout} label="Zone Name">
                                    {getFieldDecorator('zoneName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input zone name!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter Owner name"
                                            disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm}
                                        />
                                    )}
                                </Form.Item>



                                <Form.Item {...formItemLayout} label="Zone Type">
                                    {getFieldDecorator('zoneType', {
                                        initialValue: 'OPERATIONAL',
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input Zone Type"
                                            }
                                        ],
                                    })(
                                        <Select
                                            disabled
                                            placeholder="Select Zone Type" showSearch filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }

                                        >
                                            {Constants.zoneTypes?.map(locOwn => (
                                                <Select.Option
                                                    key={'owner' + locOwn.name}
                                                    value={locOwn.value}
                                                    label={locOwn.name}
                                                >
                                                    {locOwn.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>


                                <Form.Item {...formItemLayout} label="Owner Type">
                                    {getFieldDecorator('entityType', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Please input Owner Type"
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Owner Type" disabled showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }

                                            onChange={this.entityTypeChange}>
                                            {Constants.locationOwners?.map(locOwn => (
                                                <Select.Option
                                                    key={'owner' + locOwn.name}
                                                    value={locOwn.value}
                                                    label={locOwn.name}
                                                >
                                                    {locOwn.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Owner">
                                    {getFieldDecorator('entityId', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Please input Owner"
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Owner" showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                            disabled
                                            showArrow={(!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID) ? false : true}
                                            onChange={this.onUserChange}>
                                            {userOptions?.map(user => (
                                                <Select.Option
                                                    key={'user' + user.name}
                                                    value={user.id}
                                                    label={user.name}
                                                >
                                                    {user.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>



                                <Divider className="step-divider" />
                                <ZoneMapping style={{ height: 300, overflow: 'scroll', bottom: 5 }} disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm} onSelectedAreaAdd={this.patchAreaMapping} selectedPincodes={selectedPincodes1} />
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card className={'highlighter-card zone-card zone-card-edit'}>
                                <Form.Item {...formItemLayout} label={<span className="form-label-text">Select Route</span>}>
                                    {getFieldDecorator('route', {
                                        rules: [{
                                            required: true,
                                            message: 'Field cannot be blank!'
                                        }]
                                    })(

                                        <Select
                                            placeholder="Select Route"
                                            showSearch
                                            disabled={this.state.disabledRoute}
                                            labelInValue
                                            loading={this.props.loading?.loading}
                                            onChange={this.onChangeRoute}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {routes?.map((route, key) => (
                                                <Option
                                                    label={route.name}
                                                    key={route.id}
                                                >
                                                    {route.name}
                                                </Option>
                                            ))
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Card>
                        </Col>
                    </>
                }

            ]
        } else {
            steps = [
                {
                    title: 'Edit Zone',
                    content: <>
                        <Col span={12}>
                            <Card className={'highlighter-card zone-card zone-card-edit'}>
                                <Form.Item {...formItemLayout} label="Zone Name">
                                    {getFieldDecorator('zoneName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input zone name!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter Owner name"
                                            onChange={this.changeZoneName}
                                            disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm}
                                        />
                                    )}
                                </Form.Item>


                                <Form.Item {...formItemLayout} label="Zone Type">
                                    {getFieldDecorator('zoneType', {
                                        initialValue: 'OPERATIONAL',
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input Owner Type"
                                            }
                                        ],
                                    })(
                                        <Select
                                            disabled
                                            placeholder="Select Zone Type" showSearch filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }

                                        >
                                            {Constants.zoneTypes?.map(locOwn => (
                                                <Select.Option
                                                    key={'owner' + locOwn.name}
                                                    value={locOwn.value}
                                                    label={locOwn.name}
                                                >
                                                    {locOwn.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>


                                <Form.Item {...formItemLayout} label="Owner Type">
                                    {getFieldDecorator('entityType', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Please input Owner Type"
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Owner Type" showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }

                                            onChange={this.entityTypeChange}>
                                            {Constants.locationOwners?.map(locOwn => (
                                                <Select.Option
                                                    key={'owner' + locOwn.name}
                                                    value={locOwn.value}
                                                    label={locOwn.name}
                                                >
                                                    {locOwn.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Owner">
                                    {getFieldDecorator('entityId', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Please input Owner"
                                            }
                                        ],
                                    })(
                                        <Select placeholder="Select Owner" showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                            showArrow={(!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID) ? false : true}
                                            onChange={this.onUserChange}>
                                            {userOptions?.map(user => (
                                                <Select.Option
                                                    key={'user' + user.name}
                                                    value={user.id}
                                                    label={user.name}
                                                >
                                                    {user.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>



                                <Divider className="step-divider" />
                                <ZoneMapping style={{ height: 300, overflow: 'scroll', bottom: 5 }} disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm} onSelectedAreaAdd={this.patchAreaMapping} selectedPincodes={selectedPincodes1} />
                            </Card>
                        </Col>


                    </>
                },

            ]
        }





        return (
            <>
                {/* <Form labelCol={formItemLayout.labelCol} wrapperCol={formItemLayout.wrapperCol} onSubmit={this.handleSubmit}> */}
                <Form>
                    <Card className="ZonerForm" title={<h3> <Icon type="arrow-left" onClick={this.goBack} /> {isEdit ? 'Create' : 'Update'} Zone</h3>}

                        actions={!this.state.isEdit && [

                            <div style={{ float: 'left' }}>
                                {current > 0 && (
                                    <Button style={{ marginLeft: 8 }} onClick={() => this.goBackStep1()}>
                                        Back
                                    </Button>
                                )}
                            </div>,
                            <div style={{ float: 'right' }}>

                                {current == 0 && (
                                    <Button style={{ marginLeft: 8 }} onClick={this.goBack}>
                                        Cancel
                                    </Button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button type="primary" loading={(isZoneLoading || isZoneRespLoading)} onClick={(e) => this.handleSubmit(e)} disabled={this.state.updateDisabled}>
                                        Update
                                    </Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button type="primary" onClick={() => this.handleProceed()}>
                                        {current === 0 && 'Proceed'}
                                    </Button>
                                )}
                            </div>

                        ]}

                    >
                        <Spin spinning={(isZoneLoading || isZoneRespLoading)}>

                            <Row gutter={[8, 8]}>
                                {isEdit ?
                                    <Col span={12}>
                                        <Form.Item {...formItemLayout} label="Zone Name">
                                            {getFieldDecorator('zoneName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input zone name!',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Owner name"
                                                    disabled={isEdit ? false : (!zonePermission.update && zonePermission.view)}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Zone Type">
                                            {getFieldDecorator('zoneType', {
                                                initialValue: 'OPERATIONAL',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please input Owner Type"
                                                    }
                                                ],
                                            })(
                                                <Select placeholder="Select Zone Type" showSearch filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }


                                                >
                                                    {Constants.zoneTypes?.map(locOwn => (
                                                        <Select.Option
                                                            key={'owner' + locOwn.name}
                                                            value={locOwn.value}
                                                            label={locOwn.name}
                                                        >
                                                            {locOwn.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item {...formItemLayout} label="Owner Type">
                                            {getFieldDecorator('entityType', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Please input Owner Type"
                                                    }
                                                ],
                                            })(
                                                <Select placeholder="Select Owner Type" showSearch filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }

                                                    onChange={this.entityTypeChange}>
                                                    {Constants.locationOwners?.map(locOwn => (
                                                        <Select.Option
                                                            key={'owner' + locOwn.name}
                                                            value={locOwn.value}
                                                            label={locOwn.name}
                                                        >
                                                            {locOwn.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Owner">
                                            {getFieldDecorator('entityId', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Please input Owner"
                                                    }
                                                ],
                                            })(
                                                <Select placeholder="Select Owner" showSearch filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                    showArrow={(!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID) ? false : true}
                                                    onChange={this.onUserChange}>
                                                    {userOptions?.map(user => (
                                                        <Select.Option
                                                            key={'user' + user.name}
                                                            value={user.id}
                                                            label={user.name}
                                                        >
                                                            {user.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Divider />
                                        <ZoneMapping disabled={isEdit ? false : (!zonePermission.update && zonePermission.view)} onSelectedAreaAdd={this.patchAreaMapping} selectedPincodes={selectedPincodes1} />
                                    </Col> :
                                    <>
                                        {/* <Col span={18}> */}
                                        {/* <div style={{ textAlign: 'center' }}> */}
                                        <Steps size={"small"} current={current}>
                                            {steps.map(item => (
                                                <Step key={item.title} title={item.title} />
                                            ))}
                                        </Steps>
                                        {/* </div> */}

                                        {/* </Col> */}
                                        {/* <br /> */}
                                        <Divider className="step-divider" />
                                        <div className="steps-content">{steps[current].content}</div>
                                    </>
                                    // <>
                                    //     <Col span={12}>
                                    //         <h4>Edit Zone</h4>
                                    //         <Divider />
                                    //         <Form.Item {...formItemLayout} label="Zone Name">
                                    //             {getFieldDecorator('zoneName', {
                                    //                 rules: [
                                    //                     {
                                    //                         required: true,
                                    //                         message: 'Please input zone name!',
                                    //                     },
                                    //                 ],
                                    //             })(
                                    //                 <Input
                                    //                     placeholder="Enter Owner name"
                                    //                     disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm}
                                    //                 />
                                    //             )}
                                    //         </Form.Item>
                                    //         <Divider />
                                    //         <ZoneMapping disabled={(isEdit ? false : (!zonePermission.update && zonePermission.view)) || disableForm} onSelectedAreaAdd={this.patchAreaMapping} selectedPincodes={selectedPincodes1} />
                                    //         <Row>
                                    //             <Col span={3} offset={16}>
                                    //                 <Button size="large" disabled={disableForm} onClick={this.goBack} >CANCEL</Button>
                                    //             </Col>
                                    //             {
                                    //                 (isEdit || !(!zonePermission.update && zonePermission.view))
                                    //                     ? <Col span={3} offset={1}>
                                    //                         <Button size="large" disabled={disableForm} onClick={this.handleProceed} type="primary">Proceed</Button>
                                    //                     </Col>
                                    //                     : null
                                    //             }
                                    //         </Row>
                                    //     </Col>
                                    //     <Col span={1}>
                                    //         <Divider orientation="center" type="vertical" className="divider-form" />
                                    //     </Col>

                                    //     <Col span={11}>
                                    //         <h4>Select Route</h4>
                                    //         <Divider />
                                    //         <Form.Item {...formItemLayout} label={<span className="form-label-text">Select Route</span>}>
                                    //             {getFieldDecorator('route', {
                                    //                 rules: [{
                                    //                     required: true,
                                    //                     message: 'Field cannot be blank!'
                                    //                 }]
                                    //             })(

                                    //                 <Select
                                    //                     placeholder="Select Route"
                                    //                     showSearch
                                    //                     disabled={this.state.disabledRoute}
                                    //                     labelInValue
                                    //                     // onChange={props.onChangeRoute}
                                    //                     filterOption={(input, option) =>
                                    //                         option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    //                     }
                                    //                 >
                                    //                     {routes?.map((route, key) => (
                                    //                         <Option
                                    //                             label={route.name}
                                    //                             key={route.id}
                                    //                         >
                                    //                             {route.name}
                                    //                         </Option>
                                    //                     ))
                                    //                     }
                                    //                 </Select>
                                    //             )}
                                    //         </Form.Item>
                                    //     </Col>
                                    //     <div className="update-button">
                                    //         <Col span={1} offset={18}>
                                    //             <Button size="large" disabled={updateDisabled} onClick={this.goBackStep1} >Back</Button>
                                    //         </Col>
                                    //         {
                                    //             (isEdit || !(!zonePermission.update && zonePermission.view))
                                    //                 ? <Col span={2} offset={1}>
                                    //                     <Button size="large" onClick={this.handleSubmit} disabled={updateDisabled} type="primary">Update</Button>
                                    //                 </Col>
                                    //                 : null
                                    //         }
                                    //     </div>

                                    // </>

                                }
                            </Row>
                        </Spin>
                    </Card>
                    <Card>
                        {/* <Spin spinning={(isZoneLoading || isZoneRespLoading)}>
                            <Row>
                                <Col span={3} offset={(isEdit ? false : (!zonePermission.update && zonePermission.view)) ? 21 : 18} >
                                    <Button size="large" onClick={this.goBack} >CANCEL</Button>
                                </Col>
                                {
                                    (isEdit || !(!zonePermission.update && zonePermission.view))
                                        ? <Col span={3}>
                                            <Button size="large" onClick={this.handleSubmit} disabled={isSubmitDisabled} type="primary">{isEdit ? 'CREATE' : 'UPDATE'}</Button>
                                        </Col>
                                        : null
                                }
                            </Row>
                        </Spin> */}
                        {isEdit &&
                            <Spin spinning={(isZoneLoading || isZoneRespLoading)}>
                                <Row>
                                    <Col span={3} offset={18}>
                                        <Button size="large" onClick={this.goBack} >CANCEL</Button>
                                    </Col>
                                    {
                                        (isEdit || !(!zonePermission.update && zonePermission.view))
                                            ? <Col span={3}>
                                                <Button size="large" onClick={this.handleSubmit} disabled={isSubmitDisabled} type="primary">CREATE</Button>
                                            </Col>
                                            : null
                                    }
                                </Row>
                            </Spin>
                        }
                    </Card>
                </Form>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    zoneData: state.zone,
    loading: state?.zone,
    zoneUpdateResp: state?.zone?.zoneUpdateResp,
    routes: state?.zone?.routes,
    paginatedZones: state.zone?.PaginatedZones,
    isZoneLoading: state.zone?.areas?.loading,
    zoneResp: state.zone.zoneResp,
    isZoneRespLoading: state.zone?.zoneResp ? state.zone?.zoneResp?.loading : false,

    ownerCustomer: state.settingLocation?.viewResponse,
    ownervendor: state.settingLocation?.vendors,
    locationById: state.settingLocation?.locationById,
});
const mapDispatchToProps = dispatch => ({
    getZone: id => dispatch(getZone(id)),
    getRoutes: (payload) => dispatch(getAllRoutes(payload)),
    createZone: payload => dispatch(createZone(payload)),
    updateZone: (id, payload, bool) => dispatch(updateZone(id, payload, bool)),
    fetchZones: (payload, isReset) => dispatch(fetchZones(payload, isReset)),
    // fetchCustomers: param => dispatch(fetchCustomers(param)),
    // getVendors: () => dispatch(getVendors()),
    resetZone: () => dispatch(resetZone()),

});
const formWarppedComponent = Form.create({ name: 'Zone', onValuesChange: (props, val) => { } })(ZoneForm)
export default connect(mapStateToProps, mapDispatchToProps)(formWarppedComponent);