import React from 'react';
import ReactDOM from "react-dom";

import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './utils/store';
import AppRoute from './AppRoute';
import Login from "./auth/Login";
import { Route, Switch, withRouter, BrowserRouter, Redirect, Router } from "react-router-dom";
import history from "./utils/history";
import ForgetPassword from './components/authentication/forget-password';
import ResetPassword from './components/authentication/reset-password';


ReactDOM.render(
  <React.Fragment>
   <Provider store={store}>
    <Router history={history}>
      <Switch>        
        <Route
          component={withRouter(Login)}
          path={"/appv2/login"}
          exact={true}
        /> 
        <Route
          component={withRouter(ForgetPassword)}
          path={"/appv2/forget-password"}
          exact={true}
        />
        <Route
          component={withRouter(ResetPassword)}
          path={"/appv2/reset-password"}
          exact={true}
        />
        <AppRoute path="/appv2/" key="loadshare_pvt" />
        <Route
          // Catch all route for both logged in and not logged in users
          render={() => <Redirect to={"/appv2/login"} />}
        />
      </Switch>
    </Router>
    {/* <AppRoute path="/appv2/customer-login" key="loadshare_login"/> */}
  </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
