import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './zoneDashboard.scss';
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, PageHeader, Tooltip, Descriptions, Spin } from 'antd';
import { table as Table } from "../../ui/table/Table";
import { canAllow, windowRedirect } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { fetchZones } from './actions';
import { withRouter } from 'react-router-dom';
import DataCard from '../data-card/DataCard';
import activeZoneIcon from './../../../assets/img/icons/images/1_1_lead.svg';
import { getSummary } from '../../../app/appAction';
import _ from 'lodash';


const { confirm } = Modal;

class ZoneDashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            zonePermission: {
                read: canAllow(permissionTags.settings.zone.read),
                update: canAllow(permissionTags.settings.zone.update),
                create: canAllow(permissionTags.settings.zone.create),
                delete: canAllow(permissionTags.settings.zone.delete),
                view: canAllow(permissionTags.settings.zone.view),
            },
            filterPayload: {
                filters: {
                    pageNo: 1,
                    pageSize: 10,
                    locationSpecific: false,
                    fetchObjects: [
                        'ZONE_MAPPING'
                    ],
                    isActive: true
                }
            },
            searchValue: ''
        }
        this.InputSearchRef = React.createRef();
        if (!this.state.zonePermission.read) {
            windowRedirect(`${props.parent}/dashboard/`);
        }
    }

    componentDidMount() {
        const user = JSON.parse(atob(localStorage.getItem('user')));
        const loggedPartnerId = localStorage.getItem('loggedPartnerId');
        
        this.props.getSummary('BILLING_ZONE');
        this.setState(state => ({
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Pincodes',
                    dataIndex: 'pincodes',
                    key: 'pincodes',
                    render: (data, row) => {
                        const pincodes = this.getPincodes(row?.zoneMapping ? row.zoneMapping : null)
                        return pincodes.length > 100 ? pincodes.slice(0, 100) + '...' : pincodes
                    }
                },
                
                {
                    title: 'Pincode Count',
                    dataIndex: 'pincodeCount',
                    key: 'pincodeCount',
                    render: (data, row) => row.zoneMapping != null ? row.zoneMapping.length : 0
                },
                {
                    title: 'Zone type',
                    dataIndex: 'zoneType',
                    key: 'zoneType',
                },
                {
                    title: 'Entity type',
                    dataIndex: 'entityType',
                    key: 'entityType',
                },
                {
                    title: 'Created On',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: data => (moment(data).format('DD/MM/YYYY HH:mm'))
                },
                {
                    title: 'Action',
                    dataIndex: '',
                    key: 'action',
                    render: (data, row) => (<Row>
                        {
                            (state.zonePermission.update && row.isActive) && <Col span={12}><Tooltip placement="top" title={'Edit'}>
                                <Icon type="edit" onClick={() => {  this.props.history.push(`/appv2/${this.props.parent}/form/zone`, { id: row.id }) }} /></Tooltip>
                            </Col>
                        }
                        {
                            (!state.zonePermission.update && state.zonePermission.view) && <Col span={12}><Tooltip placement="top" title={'View'}>
                                <Icon type="eye" onClick={() => this.props.history.push(`/appv2/${this.props.parent}/form/zone`, { id: row.id })} /></Tooltip>
                            </Col>
                        }
                        {
                            state.zonePermission.delete && <Col span={12}><Tooltip placement="top" title={'Delete'}><Icon type="delete" onClick={() => this.showConfirm(row.id)} /></Tooltip></Col>
                        }
                    </Row >)
                },
            ]
        }));
        if (!this.props?.zoneData?.zones) { this.getPaginatedData(this.state.pageNo) }
    }

    componentDidUpdate(prevProps) {
        const { PaginatedZones, zoneData, summary } = this.props;
        if (summary !== prevProps.summary) {
            const DataCardList = Object.keys(this.props.summary).map(k => ({
                title: k.replace('_', ' '),
                description: summary[k].count,
                icon: activeZoneIcon,
                
            }))
            this.setState({
                DataCardList
            })
        }
    }

    applyPagination = (filters, isReset = false) => {
        if (isReset) {
            this.setState({
                pageNo: 1
            })
        }
        let search = '';
        if (filters.hasOwnProperty('partialContentSearch')) {
            this.setState({
                searchValue: filters['partialContentSearch']
            });
            search = filters['partialContentSearch'];
        } else {
            search = '';
        }

        if (search) {
            
            if (isNaN(filters['partialContentSearch'])) {
                this.props.fetchZones({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
            } else {

                let filter = { ...this.state.filterPayload.filters, ...filters }
                

                filter['zipCode'] = search;
                delete filter['partialContentSearch'];


                this.props.fetchZones({ filters: { ...filter } }, isReset);
            }
        } else {
            
            this.props.fetchZones({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
        }


    }

    showConfirm = (selectedZoneId) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you want to delete this zone!!!',
            onOk() {
                this.props.deleteZone(selectedZoneId, { filters: { ...this.state.filterPayload.filters } })
            },
            onCancel() { },
        });
    }

    reloadZoneList = () => {
        this.InputSearchRef.current.input.state.value = '';
        
        this.setState({
            pageNo: 1
        })
        this.applyPagination({ pageNo: 1 }, true);
    }

    getPaginatedData = (pageNo) => {
        const { PaginatedZones, } = this.props;
        
        if (!PaginatedZones ? !PaginatedZones : !PaginatedZones[pageNo]) {
            const filter = {
                pageNo
            }
            if (this.state.searchValue) {
                filter['partialContentSearch'] = this.state.searchValue
            }
            this.applyPagination(filter)
        }
        this.setState({
            pageNo
        });
    }

    getPincodes(zoneMappings) {
        const pincodes = [];
        if (zoneMappings !== null) {
            zoneMappings.forEach(zoneMapping => {
                pincodes.push(zoneMapping.entityValue);
            });
        }
        return pincodes.join(', ');

    }

    render() {
        const { columns, zonePermission, pageNo, DataCardList, searchValue } = this.state;
        const { PaginatedZones, zoneData, parent } = this.props;
        const actionBtns = (
            <Spin spinning={zoneData?.loading} indicator={<div></div>}>
                <Row style={{ marginRight: '1.5rem !important' }}>
                    <Col span={zonePermission.create ? 14 : 22}>
                        <Input.Search allowClear size={'large'} placeholder="Search by name/pincode" ref={this.InputSearchRef} onSearch={value => this.applyPagination({ partialContentSearch: value.trim() }, true)} enterButton />
                    </Col>
                    <Col span={2}>
                        <Tooltip placement="top" title={'Reload Zone List'}>
                            <Button size={'large'} onClick={() => this.reloadZoneList()} type="primary" style={{ marginLeft: 2 }}>
                                <Icon type="reload" />
                            </Button>
                        </Tooltip>
                    </Col>
                    {
                        zonePermission.create && <Col span={8}>
                            <Button size={'large'} block onClick={() => {  this.props.history.push(`/appv2/${parent}/form/zone`) }} type="primary" style={{ marginLeft: 18, width: '100%' }}>CREATE ZONE</Button>
                        </Col>
                    }
                </Row>
            </Spin>
        );
        return (
            <>
                <PageHeader
                    style={{
                        padding: 0,
                        paddingBottom: '24px'
                    }}
                    onBack={false}
                    ghost
                >
                    <Row>
                        {
                            DataCardList && DataCardList.length > 0 ? DataCardList.map(item => (
                                <Col span={8} >
                                    <DataCard {...item} />
                                </Col>
                            )) : null
                        }
                    </Row>
                </PageHeader>
                <Card className="ZoneDashBoard" title={<h2>Zone List</h2>} extra={actionBtns}>
                    <Table
                        bordered
                        locale={{
                            emptyText: (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }}
                        loading={zoneData?.loading}
                        pagination={{
                            onChange: this.getPaginatedData,
                            total: zoneData?.totalCount,
                            position: 'bottom',
                            current: pageNo,
                        }}
                        dataSource={PaginatedZones ? PaginatedZones[pageNo] : []}
                        columns={columns} />
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    zoneData: state.zone,
    PaginatedZones: state.zone?.PaginatedZones,
    summary: state.app?.summary
});
const mapDispatchToProps = dispatch => ({
    fetchZones: (payload, isReset) => dispatch(fetchZones(payload, isReset)),
    getSummary: page => dispatch(getSummary(page))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ZoneDashboard));