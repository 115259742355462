import React, { PureComponent } from 'react';
import * as actions from './actions';

import { connect } from 'react-redux';

import {
    Card, Button, Input, Row, Col,
    Empty, Tag, Icon, Modal, Badge,
    Tooltip, Table, Switch, Form, Select, Radio, DatePicker, Spin, Checkbox, Menu, Dropdown
} from 'antd';
// import { table as Table } from "../../ui/table/Table";
import moment from 'moment';
import _ from 'lodash';
import LMSEdit from '../../assets/img/icons/lmsedit.svg';
import Resend from '../../assets/img/icons/resend_invite.svg';
import DeleteUser from '../../assets/img/icons/delete_user.svg';
import { Constants, validationRules } from '../../common/utils/constants';
import * as appActions from '../../app/appAction';
import { notifyApiSuccess, checkForDownloadReportStatus } from '../../common/utils/utils';


class Users extends PureComponent {
    state = {
        showUsers: false,
        editMode: false,
        editData: {},
        editId: null,
        setAssigned: false,
        selectedSearch: undefined,
        domain: null,
        loading: false,
        showExportDownload: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.invite && this.props.invite.inviteData) {
            notifyApiSuccess("Invite Sent Successfully", "Success");
            let params = {
                "filters": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "totalCount": 0,
                    "userTypes": [
                        "USER"
                    ],
                    // "fetchObject": [
                    //     "PARTNERS",
                    //     "LOCATIONS",
                    //     "ADDRESSES",
                    //     "PINCODES",
                    //     "CITIES",
                    //     "roleIdsToLocationsMap"
                    // ],
                    "status": true,
                    "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                    // "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                    "partnerIds": [Constants.PARTNER_ID],
                    "entityTypes": ["CUSTOMER"],
                    "entityIds": [Constants.sessionUser.location.entityId]
                }
            }
            this.props.getUsers(params);
            this.props.resetFields();

        }


        if (this.props.deactivate && this.props.deactivate.deactivatedata) {
            notifyApiSuccess("Deactivated Successfully", "Success");
            let params = {
                "filters": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "totalCount": 0,
                    "userTypes": [
                        "USER"
                    ],
                    "fetchObject": [
                        "PARTNERS",
                        "LOCATIONS",
                        "ADDRESSES",
                        "PINCODES",
                        "CITIES",
                        "roleIdsToLocationsMap"
                    ],
                    "status": true,
                    "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                    "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                    "partnerIds": [Constants.PARTNER_ID],
                    "entityTypes": ["CUSTOMER"],
                    "entityIds": [Constants.sessionUser.location.entityId]
                }
            }
            this.props.getUsers(params);
            this.props.resetFields();

        }
        if (this.props?.createUser && this.props?.createUser?.userInvite) {
            this.props.form.resetFields();

            if (this.state.showUsers) {
                setTimeout(() => {
                    let params = {
                        "filters": {
                            "pageNo": 1,
                            "pageSize": 10,
                            "totalCount": 0,
                            "userTypes": [
                                "USER"
                            ],
                            "fetchObject": [
                                "PARTNERS",
                                "LOCATIONS",
                                "ADDRESSES",
                                "PINCODES",
                                "CITIES",
                                "roleIdsToLocationsMap"
                            ],
                            "status": true,
                            "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                            "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                            "partnerIds": [Constants.PARTNER_ID],
                            "entityTypes": ["CUSTOMER"],
                            "entityIds": [Constants.sessionUser.location.entityId]
                        }
                    }
                    this.props.getUsers(params);
                    this.props.resetFields();
                }, 500)

            }
            this.setState({ showUsers: false });
        }

        if (this.props?.updatedUser && this.props?.updatedUser?.userInvite) {
            this.props.form.resetFields();

            if (this.state.showUsers) {
                setTimeout(() => {
                    let params = {
                        "filters": {
                            "pageNo": 1,
                            "pageSize": 10,
                            "totalCount": 0,
                            "userTypes": [
                                "USER"
                            ],
                            "fetchObject": [
                                "PARTNERS",
                                "LOCATIONS",
                                "ADDRESSES",
                                "PINCODES",
                                "CITIES",
                                "roleIdsToLocationsMap"
                            ],
                            "status": true,
                            "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                            "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                            "partnerIds": [Constants.PARTNER_ID],
                            "entityTypes": ["CUSTOMER"],
                            "entityIds": [Constants.sessionUser.location.entityId]
                        }
                    }
                    this.props.getUsers(params);
                    this.props.resetFields();
                }, 500)

            }
            this.setState({ showUsers: false, editMode: false });
        }
        if (this.state.editMode && this.props?.editUsers?.usersData && this.props?.editUsers?.usersData?.users && this.state.setAssigned) {
            
            
            const { setFieldsValue } = this.props.form;
            this.props.editUsers.usersData.users.map(user => {
                let locations = [];
                let keys = Object.keys(user.roleIdsToLocationsMap);
                keys.map(key => {
                    user.roleIdsToLocationsMap[key].map(location => {

                        locations.push({
                            "key": location.id,
                            "label": location.name
                        })

                    })
                })

                setFieldsValue({
                    name: user.name,
                    email: user.email,
                    contactNumber: user.contactNumber,
                    userLevel: user.userLevel,

                }, () => {
                    
                    setFieldsValue({
                        locations
                    });
                    this.setState({ showUsers: true })
                })
            })

            this.setState({ setAssigned: false });
        }


        if ((this.props.downloadStatus === "SUCCESS" || this.props.downloadStatus === "FAILURE") && this.state.loading) {
            this.setState({ loading: false, showExportDownload: false });

        }
        if (this.props?.export) {
            if (this.props.export?.exportData !== prevProps.export?.exportData) {
                if (this.props?.export?.exportData?.url && this.props.export?.exportData?.url !== prevProps.export?.exportData?.url) {
                    const prop = {
                        setS3SyncStatus: this.props.setS3SyncStatus,
                        uploadedFileResp: {
                            responseUrl: this.props?.export?.exportData?.url
                        }
                    }
                    checkForDownloadReportStatus(prop, "Downloaded Successfully", "Success");
                    // reportsDownload(this.props.exportData.url, DownloadTags.s3)
                }
            }
        }

    }

    componentDidMount() {
        
        this.setState({
            domain: Constants?.sessionUser?.email ? Constants?.sessionUser?.email.split("@")[1] : null
        })
        let params = {
            "filters": {
                "pageNo": 1,
                "pageSize": 10,
                "totalCount": 0,
                "userTypes": [
                    "USER"
                ],
                "fetchObject": [
                    "PARTNERS",
                    "LOCATIONS",
                    "ADDRESSES",
                    "PINCODES",
                    "CITIES",
                    "roleIdsToLocationsMap"
                ],
                "status": true,
                "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                "partnerIds": [Constants.PARTNER_ID],
                "entityTypes": ["CUSTOMER"],
                "entityIds": [Constants.sessionUser.location.entityId]
            }
        }
        this.props.getUsers(params);
    }

    changePage = (page, pageSize) => {

        let params = {
            "filters": {
                "pageNo": page,
                "pageSize": pageSize,
                "totalCount": 0,
                "userTypes": [
                    "USER"
                ],
                "fetchObject": [
                    "PARTNERS",
                    "LOCATIONS",
                    "ADDRESSES",
                    "PINCODES",
                    "CITIES",
                    "roleIdsToLocationsMap"
                ],
                "status": true,
                "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                "partnerIds": [Constants.PARTNER_ID],
                "entityTypes": ["CUSTOMER"],
                "entityIds": [Constants.sessionUser.location.entityId]
            }
        }
        this.props.getUsers(params);

    }

    inviteUsers = () => {
        let params = {
            // "filters": {
            //     "pageNo": 1,
            //     "pageSize": 10,
            //     "totalCount": 0,
            //     "status": true,
            //     "fetchObjects": [],
            //     "entityTypes": ["PARTNER"],
            //     "entityIds": [Constants.PARTNER_ID]
            // }

            "filters": {
                "status": true,
                "entityTypes": [
                    "CUSTOMER"
                ],
                "entityIds": [
                    Constants.sessionUser.location.entityId
                ]
            }
        }
        !this.props?.locations?.locationsList && this.props.getLocations(params);
        this.setState({ showUsers: true });
    }

    showEditUsers = (data) => {        
        this.props.resetEditFields();

        let params = {
            // "filters": {
            //     "pageNo": 1,
            //     "pageSize": 10,
            //     "totalCount": 0,
            //     "status": true,
            //     "fetchObjects": [],
            //     "entityTypes": ["PARTNER"],
            //     "entityIds": [Constants.PARTNER_ID]
            // }
            "filters": {
                "status": true,
                "entityTypes": [
                    "CUSTOMER"
                ],
                "entityIds": [
                    Constants.sessionUser.location.entityId
                ]
            }
        }
        !this.props?.locations?.locationsList && this.props.getLocations(params);
        this.setState({ editMode: true, editData: data, editId: data.id, setAssigned: true }, () => {

            let params = {
                "filters": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "totalCount": 0,
                    "ids": [data.id],
                    "userTypes": [
                        "USER"
                    ],
                    "fetchObject": [
                        "PARTNERS",
                        "LOCATIONS",
                        "ADDRESSES",
                        "PINCODES",
                        "CITIES",
                        "roleIdsToLocationsMap"
                    ],
                    "status": true,
                    "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                    "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                    "partnerIds": [Constants.PARTNER_ID],
                    "entityTypes": ["CUSTOMER"],
                    "entityIds": [Constants.sessionUser.location.entityId]
                }
            }
            this.props.getEditUsers(params);
            // let { setFieldsValue } = this.props.form;
            // let { contactNumber, email, name, }
            // setFeildsValue({                
            // })
        });
    }

    handleCancel = () => {
        this.props.form.resetFields();        
        this.setState({ showUsers: false, editMode: false, editData: {}, editId: null }, () => {
            let params = {
                "filters": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "totalCount": 0,
                    "userTypes": [
                        "USER"
                    ],
                    "fetchObject": [
                        "PARTNERS",
                        "LOCATIONS",
                        "ADDRESSES",
                        "PINCODES",
                        "CITIES",
                        "roleIdsToLocationsMap"
                    ],
                    "status": true,
                    "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                    "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                    "partnerIds": [Constants.PARTNER_ID],
                    "entityTypes": ["CUSTOMER"],
                    "entityIds": [Constants.sessionUser.location.entityId]
                }
            }
            // this.props.resetFields();
            // this.props.getUsers(params);
        });
    }

    handleUpdateOk = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return;

            let params = {};

            let userId = Constants.userLocationId;
            let defaultUserId = undefined;
            if (values['userLevel'] == "CUSTOMER_USER") {
                let newLocations = values.locations.map(l => {
                    if (l == userId) {
                        defaultUserId = l.key;
                    }
                    return {
                        "id": l.key
                    }
                })


                params = {
                    "name": values['name'],
                    "id": this.state.editId,
                    "contactNumber": values['contactNumber'],
                    "email": values['email'],
                    "locationId": defaultUserId ? defaultUserId : values['locations'][0]['key'],
                    "userLevel": values['userLevel'],
                    "locations": newLocations,
                    "entityType": "CUSTOMER",
                    "entityId": Constants.sessionUser.location.entityId
                }
            } else {
                params = {
                    "name": values['name'],
                    "id": this.state.editId,
                    "contactNumber": values['contactNumber'],
                    "email": values['email'],
                    "locationId": localStorage.getItem('currentPartnerLocationId'),
                    "userLevel": values['userLevel'],
                    "locations": [{
                        id: localStorage.getItem('currentPartnerLocationId')
                    }],
                    "entityType": "CUSTOMER",
                    "entityId": Constants.sessionUser.location.entityId
                }
            }




            this.props.updateUser(params, this.state.editId);
        });
    }

    handleCreateOk = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return;

            let params = {};

            let userId = Constants.userLocationId;
            let defaultUserId = undefined;
            if (values['userLevel'] == "CUSTOMER_USER") {
                let newLocations = values.locations.map(l => {
                    if (l.key == userId) {
                        defaultUserId = l.key;
                    }
                    return {
                        "id": l.key
                    }
                })


                params = {
                    "name": values['name'],
                    "contactNumber": values['contactNumber'],
                    "email": values['email'],
                    "locationId": defaultUserId ? defaultUserId : values['locations'][0]['key'],
                    "userLevel": values['userLevel'],
                    "locations": newLocations,
                    "entityType": "CUSTOMER",
                    "entityId": Constants.sessionUser.location.entityId
                }
            } else {
                params = {
                    "name": values['name'],
                    "contactNumber": values['contactNumber'],
                    "email": values['email'],
                    "locationId": localStorage.getItem('currentPartnerLocationId'),
                    "userLevel": values['userLevel'],
                    "locations": [{
                        id: localStorage.getItem('currentPartnerLocationId')
                    }],
                    "entityType": "CUSTOMER",
                    "entityId": Constants.sessionUser.location.entityId
                }
            }



            
            this.props.addUsers(params);
        });
    }

    applyFilters = (value) => {

        if (value) {
            this.setState({ selectedSearch: value });
            let params = {
                "filters": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "totalCount": 0,
                    "userTypes": [
                        "USER"
                    ],
                    "fetchObject": [
                        "PARTNERS",
                        "LOCATIONS",
                        "ADDRESSES",
                        "PINCODES",
                        "CITIES",
                        "roleIdsToLocationsMap"
                    ],
                    "status": true,
                    "partialContentSearch": value,
                    "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                    "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                    "partnerIds": [Constants.PARTNER_ID],
                    "entityTypes": ["CUSTOMER"],
                    "entityIds": [Constants.sessionUser.location.entityId]
                }
            }
            this.props.getUsers(params);
        } else {
            let params = {
                "filters": {
                    "pageNo": 1,
                    "pageSize": 10,
                    "totalCount": 0,
                    "userTypes": [
                        "USER"
                    ],
                    "fetchObject": [
                        "PARTNERS",
                        "LOCATIONS",
                        "ADDRESSES",
                        "PINCODES",
                        "CITIES",
                        "roleIdsToLocationsMap"
                    ],
                    "status": true,
                    "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                    "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                    "partnerIds": [Constants.PARTNER_ID],
                    "entityTypes": ["CUSTOMER"],
                    "entityIds": [Constants.sessionUser.location.entityId]
                }
            }
            this.props.getUsers(params);
            this.setState({ selectedSearch: undefined });
        }

    }

    clearSearch = () => {
        let params = {
            "filters": {
                "pageNo": 1,
                "pageSize": 10,
                "totalCount": 0,
                "userTypes": [
                    "USER"
                ],
                "fetchObject": [
                    "PARTNERS",
                    "LOCATIONS",
                    "ADDRESSES",
                    "PINCODES",
                    "CITIES",
                    "roleIdsToLocationsMap"
                ],
                "status": true,
                "fetchObjects": ["USER_LOCATION_ROLE_MAPPING", "LOCATIONS"],
                "userLevels": ["CUSTOMER_ADMIN", "CUSTOMER_USER"],
                "partnerIds": [Constants.PARTNER_ID],
                "entityTypes": ["CUSTOMER"],
                "entityIds": [Constants.sessionUser.location.entityId]
            }
        }
        this.props.getUsers(params);
        this.setState({ selectedSearch: undefined });
    }

    changeSearch = (e) => {
        this.setState({ selectedSearch: e.target.value });
    }

    resendInvite = (record) => {
        this.props.getResendInvite(record.id);
    }

    deActivateUser = (record) => {
        this.props.deactivateUser(record.id);
    }


    validateEmail = (rule, value, callback) => {
        if (this.state.domain !== null) {
            var regex = new RegExp("[a-zA-Z0-9._\-]+[@]+" + `${this.state.domain}`);
            if (regex.test(value)) {
                callback();
            } else {
                callback("Please Enter valid Email");
            }
        } else {
            callback();
        }



    };


    exportCustomer = () => {
        this.props.setS3SyncStatusReset();
        this.setState({ loading: true, showExportDownload: true });
        let params = {
            "userId": Constants.sessionUser.id,
            "userRequestFilters": {
                "userTypes": [
                    "USER"
                ],
                "status": true,
                "fetchObjects": [
                    "USER_LOCATION_ROLE_MAPPING",
                    "LOCATIONS"
                ],
                "partnerIds": [
                    Constants.sessionUser.partner.id
                ],
                "entityTypes": [
                    "CUSTOMER"
                ],
                "entityIds": [
                    Constants.sessionUser.location.entityId
                ]
            }
        }
        

        this.props.exportCustomer(params);
    }


    render() {

        const menu = (data, record) => (
            <Menu>
                {/* {trip?.attributes?.tripType == "DV" &&
                <SubMenu
                    key="sub1"
                    title={
                        <span>
                            <Icon type="phone" />
                            <span>Driver Info </span>
                        </span>
                    }
                >
                    <Menu.ItemGroup>
                        <Menu.Item key="1">
                            <div>{trip?.vehicleNumber?.attributes?.name}</div>
                            <div>{trip?.vehicleNumber?.attributes?.mobileNumber}<Icon type="copy" /></div>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </SubMenu>} */}


                {record.userLevel !== 'Super Admin' && record?.status && record?.isActivationExpired ?
                    <Menu.Item key="resend">
                        <div onClick={() => this.resendInvite(record)}><img src={Resend} /> Resend Invite </div>
                    </Menu.Item> :
                    <Menu.Item key="resend" disabled={true}>
                        <div><img src={Resend} /> Resend Invite </div>
                    </Menu.Item>
                }

                {record.userLevel !== 'Super Admin' &&
                    <Menu.Item key="deactivate">
                        <div onClick={() => this.deActivateUser(record)}><img src={DeleteUser} /> Deactivate User </div>
                    </Menu.Item>
                }

            </Menu>
        );

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 70
            },
            {
                title: 'Mobile Number',
                dataIndex: 'contactNumber',
                key: 'contactNumber',
                width: 80
            },
            {
                title: 'E-Mail',
                dataIndex: 'email',
                key: 'email',
                width: 100
            },
            // {
            //     title: 'Created on',
            //     dataIndex: 'createdon',
            //     key: 'createdon',
            //     width: 80
            // },
            // {
            //     title: 'Mandatory Required',
            //     dataIndex: 'completion',
            //     key: 'completion',
            //     width: 80
            // },
            {
                title: 'Access Level',
                width: 80,
                dataIndex: 'userLevel',
                key: 'userLevel'
            },
            {
                title: 'Locations',
                width: 80,
                dataIndex: 'locations',
                key: 'locations',
                render: (locations) => {
                    return <>
                        {locations.length > 0 &&
                            locations[0].label

                        }
                        {locations.length == 1 ? <></> : <> <b>+{locations.length - 1} Locations</b></>}


                    </>
                }
            },
            // {
            //     title: 'Locations',
            //     dataIndex: 'mandatory',
            //     key: 'mandatory',
            //     width: 80
            // },
            {
                title: 'Invited Date',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                width: 90
            },
            // {
            //     title: 'Hands on Date',
            //     dataIndex: 'created',
            //     key: 'created',
            //     width: 80
            // },
            {
                title: 'Status',
                dataIndex: 'userStatus',
                key: 'userStatus',
                width: 10
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'action',
                width: 50,
                render: (data, row) => (<Row gutter={[24, 24]}>

                    {data.userLevel == 'Super Admin' ? <Col span={8}><Tooltip placement="top" title={'Edit'}>
                        <div style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "4px",
                            backgroundColor: "#f2f2f5"
                        }}
                        >
                            <img src={LMSEdit} style={{ opacity: '0.5', cursor: 'not-allowed', marginTop: "8px", marginLeft: "8px" }} />
                        </div>
                        {/* <Icon type="edit" onClick={() => this.showCreateModule("edit", data)} /> */}
                    </Tooltip>
                    </Col> : <Col span={8}><Tooltip placement="top" title={'Edit'}>
                        <div style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "4px",
                            backgroundColor: "#f2f2f5"
                        }}
                        >
                            <img src={LMSEdit} style={{ cursor: 'pointer', marginTop: "8px", marginLeft: "8px" }} onClick={() => this.showEditUsers(data)} />
                        </div>
                        {/* <Icon type="edit" onClick={() => this.showCreateModule("edit", data)} /> */}
                    </Tooltip>
                        </Col>}
                    <Col span={8}>
                        <Dropdown overlay={menu(data, row)} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <div style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "4px",
                                    backgroundColor: "#f2f2f5"
                                }}
                                >
                                    <Icon
                                        style={{
                                            marginTop: "8px",
                                            marginLeft: "8px"
                                        }}

                                        type="more" />
                                </div>

                            </a>

                        </Dropdown>
                    </Col>

                    {/* <Col span={8}><Tooltip placement="top" title={row.lmsStatus == 'ACTIVE' ? 'Enabled' : 'Disabled'}>
                        <Switch size="small" checked={row.lmsStatus == 'ACTIVE' ? true : false} onChange={(checked) => this.changeStatus(checked, data)} /></Tooltip>
                    </Col> */}
                </Row >)
            },
        ];
        const data = [];
        let total = 0, current = 0;



        let lmsData = this.props?.users?.usersData?.users?.map(user => {

            let locations = [];
            let keys = user.roleIdsToLocationsMap ? Object.keys(user.roleIdsToLocationsMap) : [];
            keys.map(key => {
                user.roleIdsToLocationsMap[key].map(location => {

                    locations.push({
                        "key": location.id,
                        "label": location.name
                    })

                })
            })



            data.push(
                {
                    ...user,
                    name: user.name,
                    contactNumber: user.contactNumber,
                    email: user.email,
                    userLevel: user.userLevel == 'CUSTOMER_ADMIN' ? 'Admin' : (user.userLevel == 'CUSTOMER_SUPER_ADMIN' ? 'Super Admin' : 'User'),
                    updatedAt: moment(user.updatedAt).format("DD-MMM-YYYY HH:mm"),
                    userStatus: user.status ? (user?.isRegistrationVerified ? <Tag color="blue">Active</Tag> : (user?.isActivationExpired ? <Tag color="red">Expired</Tag> : <Tag color="orange">Invited</Tag>)) : <Tag color="#656565">Inactive</Tag>,
                    locations: locations,

                    id: user.id,

                    // kycStatus: <>{user.kycStatus == 'ACTIVE' ? <Tag color="blue">Active</Tag> : <Tag>Inactive</Tag>}</>
                    // status: 'Active'
                }
            );



        });
        total = this.props.users?.usersData?.totalCount;
        current = this.props.users?.usersData?.currentPageNo;



        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { Option } = Select;

        let accessLevel = getFieldsValue(['userLevel']);


        return (
            <Card className="UsersDashboard" title={<h2>Users</h2>} >
                <Row gutter={[9, 9]}>
                    <Col span={9}>
                        <Input.Search
                            value={this.state.selectedSearch}
                            placeholder="Search by Name | Mobile Number"
                            // enterButton
                            onChange={this.changeSearch}
                            onSearch={this.applyFilters}
                            suffix={<>{this.state.selectedSearch && <Icon style={{ cursor: 'pointer', marginRight: "4px" }} onClick={this.clearSearch} type="close-circle" />}</>}
                            enterButton
                        />
                    </Col>
                    <Col span={2} offset={8}>
                        <Button
                            type="primary"
                            onClick={this.inviteUsers}
                            style={{ marginLeft: 70 }}
                        >
                            <Icon type="plus" />Invite User
                       </Button>
                    </Col>
                    {/* <Col span={2} push={1}>
                        <Button><Icon type="upload" /> Import</Button>
                    </Col> */}
                    <Col span={2} push={1}>
                        <Button style={{ marginLeft: 70 }} onClick={this.exportCustomer}> <Icon type="download" /> Export</Button>
                    </Col>
                </Row>
                <Spin spinning={this.props?.users?.loading}>
                    <Table
                        bordered
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            total: total,
                            current: current,
                            onChange: this.changePage
                        }}
                    />
                </Spin>


                <Modal
                    title={this.state.editMode ? 'Edit User' : 'Add new User'}
                    visible={this.state.showUsers}
                    style={{ top: 25 }}
                    closable={false}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <>
                            {this.state.editMode ?
                                <Button key="submit" type="primary" onClick={this.handleUpdateOk}>
                                    Update
                                </Button>
                                :
                                <Button key="submit" type="primary" onClick={this.handleCreateOk}>
                                    Add
                                </Button>
                            }

                        </>
                    ]}
                >

                    <Form>
                        <Row gutter={[1, 1]}>
                            <Col span={24}>
                                <Row gutter={[0, 0]}>
                                    <Col span={24}>
                                        <Form.Item colon={false} label={<span className="form-label-text">Name</span>}>
                                            {getFieldDecorator('name', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input name!',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter name"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item colon={false} label={<span className="form-label-text">Mobile Number</span>}>
                                            {getFieldDecorator('contactNumber', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input Mobile Number!',
                                                    },
                                                    {
                                                        pattern: validationRules.mobile,
                                                        message: "Please enter valid mobile number"
                                                    }
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Mobile Number"
                                                    disabled={this.state.editMode}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item colon={false} label={<span className="form-label-text">Email</span>}>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input Email!',
                                                    },
                                                    {
                                                        // pattern: validationRules.domainEmail,
                                                        // message: "Please enter valid email"
                                                        validator: this.validateEmail
                                                    }

                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Email"
                                                    disabled={this.state.editMode}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item colon={false} label={<span className="form-label-text">Access Level</span>}>
                                            {getFieldDecorator('userLevel', {
                                                initialValue: 'CUSTOMER_USER',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select access type!',
                                                    },
                                                ],
                                            })(
                                                <Radio.Group>
                                                    <Radio value={'CUSTOMER_ADMIN'}>Admin  <Tooltip placement="rightTop" title={'Admin will have access to all locations'}><Icon type="info-circle" /></Tooltip></Radio>
                                                    <Radio value={'CUSTOMER_USER'}>User  <Tooltip placement="rightTop" title={'User can be given access to multiple locations from the list'}><Icon type="info-circle" /></Tooltip></Radio>
                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    {accessLevel?.userLevel == 'CUSTOMER_USER' &&
                                        <Col span={24}>
                                            <Form.Item colon={false} label={<span className="form-label-text">Location(s)</span>}>
                                                {getFieldDecorator('locations', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please select location!',
                                                        },
                                                    ],
                                                })(
                                                    <Select
                                                        placeholder="Select Location"
                                                        showArrow
                                                        labelInValue
                                                        mode="multiple"
                                                        showSearch
                                                        loading={this.props?.locations?.loading}
                                                        onSelect={this.selectLocation}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {this.props?.locations?.locationsList?.map(location => (
                                                            <Select.Option
                                                                key={location.id}
                                                                value={location.id}
                                                                label={location.name}
                                                            >
                                                                {location.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    }
                                </Row>

                            </Col>
                        </Row>


                    </Form>

                </Modal>

                <Modal
                    title={false}
                    visible={this.state.showExportDownload}
                    centered
                    maskClosable={false}
                    closable={false}
                    footer={false}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Spin size="large" tip={<span style={{ color: 'black' }}>Download In Progress</span>} />
                    </div>

                </Modal>


            </Card>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    users: state?.customerUsers?.users,
    editUsers: state?.customerUsers?.editUsers,
    createUser: state?.customerUsers?.createUser,
    updatedUser: state?.customerUsers?.updateUser,
    locations: state?.customerUsers?.locations,
    invite: state?.customerUsers?.invite,
    export: state?.customerUsers?.export,
    downloadStatus: state?.app?.s3Status,
    deactivate: state?.customerUsers?.deactivate
});
const mapDispatchToProps = dispatch => ({
    getUsers: (params) => dispatch(actions.getUsers(params)),
    addUsers: (params) => dispatch(actions.addUsers(params)),
    updateUser: (params, id) => dispatch(actions.updateUser(params, id)),
    getLocations: (params) => dispatch(actions.getLocations(params)),
    getEditUsers: (params) => dispatch(actions.getEditUsers(params)),
    resetFields: () => dispatch(actions.resetFields()),
    resetEditFields: () => dispatch(actions.resetEditFields()),
    getResendInvite: (id) => dispatch(actions.getResendInvite(id)),
    exportCustomer: (params) => dispatch(actions.exportCustomer(params)),
    setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
    deactivateUser: (id) => dispatch(actions.deactivateUser(id)),
    setS3SyncStatusReset: () => dispatch(appActions.setS3SyncStatusReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Users', onValuesChange: (props, val) => { } })(Users));

