import React, { Component } from 'react';
import '../login.scss'
import './reset-password.scss';
import { Card, Button, Input, Row, Col, Form } from 'antd';
import Login1 from '../../../assets/img/login-1.png';
import Login2 from '../../../assets/img/login-2.png';
import email from '../../../assets/img/icons/email.svg';
import Loadshare from '../../../assets/img/loadshare-logo.svg';
import { Constants, validationRules } from '../../../common/utils/constants';
import { apiRequest } from '../../../common/utils/apiGateway';
import { checkHttpStatus, notifyApiError } from '../../../common/utils/utils';

import moment from 'moment';


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    
    const token = props.location.search.split('=')[1]
    if (token) {
      apiRequest({
        url: `/b2b/v1/user/token?validateToken=true`,
        method: "POST",
        data: {
          token
        }
      }).then(({ data }) => {


        if (checkHttpStatus(data?.status) && data.response?.valid) {
          
          this.setState({
            validatedToken: true
          })
        } else {
          notifyApiError(data?.response?.exception, 'Error');
          this.setState({
            validatedToken: false,
            tokenVerification: data.response
          })
        }
      });
    } else {
      this.setState({ validatedToken: false })
    }
    this.state = {
      token,
      isSuccess: false,
      validatedToken: false,
      tokenVerification: {},
      newTokenGenerated: false
    }
  }

  componentDidMount() {
    localStorage.clear();
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
  }

  generateNewToken = () => {
    apiRequest({
      url: `/b2b/v1/user/token?generateNewToken=true`,
      method: "POST",
      data: {
        token: this.state.token
      }
    }).then(({ data }) => {
      console.log("data->>", data)

      if (checkHttpStatus(data?.status) && data.response?.emailGenerated) {
        console.log("data->>", data)
        this.setState({
          newTokenGenerated: true
        })
      } else {
        notifyApiError(data.response.exception, 'Error');
        this.setState({
          newTokenGenerated: false
        })
      }
    });
  }

  handleForgetPasswordn = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      let params = {
        token: this.state.token,
        password: values['pass']
      }
      apiRequest({
        url: `/b2b/v1/user/token?passwordReset=true`,
        method: "POST",
        data: params
      }).then(({ data }) => {


        if (checkHttpStatus(data?.status) && data.response?.passwordReseted) {
          
          this.setState({
            isSuccess: true
          })
        } else {
          notifyApiError(data.response.exception, 'Error');
          this.props.form.setFieldsValue({
            pass: '',
            cpass: ''
          })
        }
      });
    });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('pass')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { isSuccess, validatedToken, newTokenGenerated } = this.state;
    return (
      <div className="containers">
        <Row>
          <Col span={12} style={{
            background: `url(${Login1}) no-repeat center center`,
            backgroundSize: 'cover'
          }}>
            <div
              className="centered-1"

            />
          </Col>
          <Col span={12} className="login-form" style={{
            background: `url(${Login2}) no-repeat center center`,
            backgroundSize: 'cover'
          }}>
            <div
              className="centered"

            >
              {/* <Card style={{ width: 100, heigth: 100 }}>Name</Card> */}
              <Card
                className="login-card"
                style={{
                  width: "410px",
                  borderRadius: '10px'
                }}
              >
                <h1 className="logo">

                  <img src={Loadshare} />

                </h1>
                <br />


                {
                  !isSuccess && validatedToken && !newTokenGenerated ?

                    <div>
                      <br />
                      <div className="reset-password-title">Reset Password</div>
                      <br />
                      <Form layout="vertical" onSubmit={this.handleForgetPasswordn}>

                        <Form.Item label={<span className="form-label-text">New Password</span>}>
                          {getFieldDecorator('pass', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input password!'
                              },
                            ],
                          })(
                            <Input.Password
                              placeholder="Enter your New password"
                            />,
                          )}
                        </Form.Item>
                        <Form.Item label={<span className="form-label-text">Re-Enter Password</span>}>
                          {getFieldDecorator('cpass', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input password!'
                              },
                              {
                                validator: this.compareToFirstPassword,
                              },
                            ],
                          })(
                            <Input.Password
                              placeholder="Enter your re-enter password"
                            />,
                          )}
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" className="login-button" htmlType="submit">
                            {'Reset Password'}
                          </Button>
                        </Form.Item>
                      </Form>
                    </div> :
                    <>
                      {!validatedToken ?
                        <>
                          {!this.state.isTokenExpired ?
                            <>
                              <h1 className="message-logo">

                                <img src={email} />

                              </h1>
                              <p>Invalid Link.
                            <Button onClick={e => { this.props.history.push('/appv2/login') }} type="link">
                                  <span style={{ textDecoration: 'underline', color: 'blue' }}>{'Login'}</span>
                                </Button>
                              </p>


                            </>
                            :
                            <>
                              <h1 className="message-logo">

                                <img src={email} />

                              </h1>

                              <Button onClick={this.generateNewToken} type="link" className="ghost-button" ghost block>
                                <span style={{ textDecoration: 'underline', color: 'blue' }}>{'Generate New Token'}</span>
                              </Button>
                            </>
                          }


                        </>
                        :
                        <>
                          <h1 className="message-logo">

                            <img src={email} />

                          </h1>
                          <small className="small-text">Password has been reset successfully Please visit.</small>
                          <Button onClick={e => { this.props.history.push('/appv2/login') }} type="link" className="ghost-button" ghost block>
                            <span style={{ textDecoration: 'underline', color: 'blue' }}>{'Login'}</span>
                          </Button>
                        </>
                      }

                    </>
                }


              </Card>
            </div>
            <div className="copywrite-text">
              © 2022 Loadshare Networks Pvt Ltd
                        </div>
          </Col>
        </Row>
      </div>

    )
    // return (
    //   <Row>
    //     <Col span={12}>
    //       <div className="login-1">
    //         <img src={Login1} style={{ objectFit: 'cover', width: '100%' }} />
    //       </div>
    //     </Col>
    //     <Col span={12}>
    //       <div className="container">
    //         <div className="login-2">
    //           <img src={Login2} style={{ objectFit: 'cover', width: '100%' }} />
    //         </div>
    //         <Card
    //           className="login-card"
    //         >
    //           <h1 className="logo">

    //             <img src={Loadshare} />

    //           </h1>
    //           {
    //             !isSuccess && validatedToken && !newTokenGenerated ?

    //               <div>
    //                 <br />
    //                 <h3>Reset Password</h3>
    //                 <Form layout="vertical" onSubmit={this.handleForgetPasswordn}>

    //                   <Form.Item label={<span className="form-label-text">New Password</span>}>
    //                     {getFieldDecorator('pass', {
    //                       rules: [
    //                         {
    //                           required: true,
    //                           message: 'Please input password!'
    //                         },
    //                       ],
    //                     })(
    //                       <Input.Password
    //                         placeholder="Enter your New password"
    //                       />,
    //                     )}
    //                   </Form.Item>
    //                   <Form.Item label={<span className="form-label-text">Re-Enter Password</span>}>
    //                     {getFieldDecorator('cpass', {
    //                       rules: [
    //                         {
    //                           required: true,
    //                           message: 'Please input password!'
    //                         },
    //                         {
    //                           validator: this.compareToFirstPassword,
    //                         },
    //                       ],
    //                     })(
    //                       <Input.Password
    //                         placeholder="Enter your re-enter password"
    //                       />,
    //                     )}
    //                   </Form.Item>
    //                   <Form.Item>
    //                     <Button type="primary" className="login-button" htmlType="submit">
    //                       {'Reset Password'}
    //                     </Button>
    //                   </Form.Item>
    //                 </Form>
    //               </div> :
    //               <>
    //                 {!validatedToken ?
    //                   <>
    //                     {!this.state.isTokenExpired ?
    //                       <>
    //                         <h1 className="message-logo">

    //                           <img src={email} />

    //                         </h1>
    //                         <p>Invalid Link.
    //                         <Button onClick={e => { this.props.history.push('/appv2/login') }} type="link">
    //                             <span style={{ textDecoration: 'underline', color: 'blue' }}>{'Login'}</span>
    //                           </Button>
    //                         </p>


    //                       </>
    //                       :
    //                       <>
    //                         <h1 className="message-logo">

    //                           <img src={email} />

    //                         </h1>

    //                         <Button onClick={this.generateNewToken} type="link" className="ghost-button" ghost block>
    //                           <span style={{ textDecoration: 'underline', color: 'blue' }}>{'Generate New Token'}</span>
    //                         </Button>
    //                       </>
    //                     }


    //                   </>
    //                   :
    //                   <>
    //                     <h1 className="message-logo">

    //                       <img src={email} />

    //                     </h1>
    //                     <small className="small-text">Password has been reset successfully Please visit.</small>
    //                     <Button onClick={e => { this.props.history.push('/appv2/login') }} type="link" className="ghost-button" ghost block>
    //                       <span style={{ textDecoration: 'underline', color: 'blue' }}>{'Login'}</span>
    //                     </Button>
    //                   </>
    //                 }

    //               </>
    //           }


    //         </Card>
    //       </div>


    //     </Col>
    //   </Row>
    // )
  }
}

export default Form.create({ name: 'ResetPassword' })(ResetPassword);