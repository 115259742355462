import React from "react";
import { Constants } from "./common/utils/constants";
import NoPermission from "../src/components/shared/NoPermission/NoPermission";
import ZoneDashboard from "./components/shared-components/zone/ZoneDashboard";
import ZoneForm from "./components/shared-components/zone/ZoneForm";
import CustomerUsers from "./components/customer-users/users";
import LiveLoads from "./components/customer-panel/live-loads";
import DeliverLoads from "./components/customer-panel/delivered-loads";
import CustomerTracking from "./components/customer-tracking/tracking";
import CustomerVehicleTracking from "../src/components/customer-tracking/VehicleTracking/VehicleTracking";
import CustomerRouteTracking from "../src/components/customer-tracking/RouteTracking/RouteTracking";
import DownloadFile from "./components/shared-components/download-file";

import { permissionTags } from './configs/permissionTags';
console.log("permissionTags", permissionTags)


const loginRoutes = []

const customerTrackingRoutes = [
    {
        path: "/appv2/customer-tracking/dashboard/tracking",
        name: "Tracking",
        component: CustomerTracking,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/customer-tracking/dashboard/customer-vehicle-tracking",
        name: "Vehicle Tracking",
        component: CustomerVehicleTracking,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/customer-tracking/dashboard/customer-route-tracking/:id",
        name: "Route Tracking",
        component: CustomerRouteTracking,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/customer-tracking/dashboard",
        name: "Tracking",
        component: CustomerTracking,
        layout: "MainLayout",
        isReact: true
    },
]

const customerPanelRoutes = [
    {
        path: "/appv2/customer-users/dashboard",
        name: "Users",
        component: CustomerUsers,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/live-loads/dashboard",
        name: "Live Loads",
        component: LiveLoads,
        layout: "MainLayout",
        isReact: true
    },
    {
        path: "/appv2/deliver-loads/dashboard",
        name: "Deliver Loads",
        component: DeliverLoads,
        layout: "MainLayout",
        isReact: true
    },
]

const miscRoutes = [
    {
        path: "/appv2/download",
        component: DownloadFile,
        name: "Download",
        layout: "BlankLayout",
        redirect: true,
        isReact: true
    },
    {
        path: "/appv2/noPermission/dashboard",
        name: "No Permission",
        component: NoPermission,
        layout: "NosidebarLayout"
    },
]

const routes = [
    ...loginRoutes,
    ...customerTrackingRoutes,
    ...customerPanelRoutes,
    ...miscRoutes

];

export default routes;
