import { pModules, permissionTags, paTags } from "./permissionTags";
import * as utils from "../common/utils/utils";
import {
  Constants,
  checkConfigurationsForPermission
} from "../common/utils/constants";


let pincodePermissionCheckInConfiguration = congiValue => {
  const data = utils.checkConfigurationsForPermission(congiValue);

  if (
    data &&
    data.length &&
    data.indexOf(JSON.stringify(Constants.sessionUser.id)) > -1
  ) {
    return true;
  }

  return false;
};

let configPermissionCheckInConfiguration = congiValue => {
  const data = utils.checkConfigurationsForPermission(congiValue);

  if (
    data &&
    data.length &&
    data.indexOf(JSON.stringify(Constants.sessionUser.id)) > -1
  ) {
    return true;
  }

  return false;
};

// path : path of menu/tab to be appended in url
// label: string to be displayed on UI for a menu/tab
// name : key for a menu/tab present in permissions object
export const menuconfig = [ 
  
  {
    key: utils.generateID(),
    path: "live-loads",
    label: "Live Loads",
    name: pModules.live_loads.subModule.live_loads,
    inactiveIcon: "live_loads_inactive.svg",
    activeIcon: "live_loads_inactive.svg",
    canAllow: utils.canAllow(permissionTags.live_loads.live_loads.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Live Loads",
        path: "dashboard",
        name: pModules.live_loads.subModule.live_loads,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.live_loads.live_loads.read)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "deliver-loads",
    label: "Delivered Loads",
    name: pModules.deliver_loads.subModule.deliver_loads,
    inactiveIcon: "deliver_loads_inactive.svg",
    activeIcon: "deliver_loads_inactive.svg",
    canAllow: utils.canAllow(permissionTags.deliver_loads.deliver_loads.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Delivered Loads",
        path: "dashboard",
        name: pModules.deliver_loads.subModule.deliver_loads,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.deliver_loads.deliver_loads.read)
      },
    ]
  },
  {
    key: utils.generateID(),
    path: "customer-tracking",
    label: "Tracking",
    name: pModules.customer_tracking.subModule.docket_tracking,
    inactiveIcon: "customer_tracking_inactive.svg",
    activeIcon: "customer_tracking_inactive.svg",
    canAllow: utils.canAllow(permissionTags.customer_tracking.docket_tracking.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Tracking",
        path: "tracking",
        name: pModules.customer_tracking.subModule.docket_tracking,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.customer_tracking.docket_tracking.read)
      },
      {
        label: "Vehicle Tracking",
        path: "customer-vehicle-tracking",
        name: pModules.customer_tracking.subModule.vehicle_tracking,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.customer_tracking.vehicle_tracking.read)
      }
    ]
  },
  {
    key: utils.generateID(),
    path: "customer-users",
    label: "Users",
    name: pModules.customer_settings.subModule.user_settings,
    inactiveIcon: "user_inactive.svg",
    activeIcon: "user_inactive.svg",
    canAllow: utils.canAllow(permissionTags.customer_settings.user_settings.read),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Users",
        path: "dashboard",
        name: pModules.customer_settings.subModule.user_settings,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.customer_settings.user_settings.subModule)
      },
    ]
  }
];
