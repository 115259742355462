import React from 'react';
import { Constants } from '../../../common/utils/constants';
// import TrackingPopup from '../../TrackingPopup/TrackingPopup';
import { Modal } from 'antd';


const TrackingPopupModal = (props) => {
  return (
    <Modal
      onCancel={props.onCancel}
      visible={props.visible}
      width="85vw"
      footer={<div />}
      centered
      destroyOnClose={true}
    >
      {/* <TrackingPopup
        getDetailInput={props.input}
        waybillNo={props.waybillNo}
        source={props.source}
      /> */}
    </Modal>
  )
}

export default TrackingPopupModal;