import React, { PureComponent, Component } from 'react';
import * as actions from './actions';

import { connect } from 'react-redux';

import {
    Card, Button, Row, Col, Typography,
    Empty, Tag, Icon, Modal, Badge,
    Tooltip, Table, Switch, Form, Select, Radio, DatePicker, Spin, Checkbox, Collapse
} from 'antd';
import moment from 'moment';
import _ from 'lodash';
import TrackingIcon from "../../assets/img/icons/4_w.svg";
import TrackingSpinner from "../../assets/img/icons/ripple.svg";
import * as utils from "../../common/utils/utils";
import { input as Input, InputApi } from "../ui/input/input";

import HeliosRoute from './HeliosRoute';
import "./tracking.scss";
import img from '../../assets/img/spinner.svg';
import Paste from '../../assets/img/paste.svg';
import Truck from '../../assets/img/delivery-truck.svg';
import HeliosStepper from "../customer-tracking/RouteTracking/HeliosStepper";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../ui/notification/Notification';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { saveAs } from 'file-saver';

import './tracking.scss';
const { TextArea } = InputApi;
const { Panel } = Collapse;



class NextArrow extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const { className, style, onClick } = this.props;

        return (
            <Icon type="right-circle"
                className={className}
                style={{ ...style, display: "block", color: "black", zIndex: 999, fontSize: 20 }}
                onClick={onClick}
            />
        )
    }
}

class PrevArrow extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const { className, style, onClick } = this.props;

        return (
            <Icon type="left-circle"
                className={className}
                style={{ ...style, display: "block", color: "black", zIndex: 999, fontSize: 20 }}
                onClick={onClick}
            />
        )
    }
}

class CustomerTracking extends PureComponent {
    constructor(props) {
        super(props);
        const _this = this;
        this.state = {
            searchBy: 'invoice',
            showModal: false,
            showErrMessege: false,
            activeKey: 1,
            selectedIndex: null,
            inputData: "",
            inputPlaceholder: "Enter Docket No./Inovice No.",
            consignVisible: false,
            consign: {},
            apiCalled: false,
            expand: true,
            copied: false,
            trackButtonDisabled: true,
            text: '',
            URL: '',
            consignImages: [],
            copySuccess: '',
            podImageIndex: 0,
            errorMessege: 'The invoice number is associated with multiple dockets, please track by docket number.',
            isfirstLoad: true,
            settings: {
                afterChange: function (i) {
                    _this.setState({ podImageIndex: i })
                },
                dots: false,
                initialSlide: 0,
                currentSlide: 0,
                arrows: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                nextArrow: <NextArrow />,
                prevArrow: <PrevArrow />
            },
        }
        this.props.resetFields();
    }

    componentDidMount() {
        
        this.props.getAccessKeys();
        if (this?.props?.location?.state?.waybillNo) {
            let { waybillNo } = this?.props?.location?.state;
            this.setState({ inputData: waybillNo, apiCalled: true, trackButtonDisabled: true });
            // this.onTrackClick();
        }
        if (this?.props?.location?.state?.invoiceNumber) {
            let { invoiceNumber } = this?.props?.location?.state;
            this.setState({ inputData: invoiceNumber, apiCalled: true, trackButtonDisabled: true });
            // this.onTrackClick();
        }
        if (this?.props?.location?.state?.searchedBy) {
            this.setState({
                searchBy: this?.props?.location?.state?.searchedBy
            })
        }
        this.TrackingcopyArea = React.createRef();
        this.copyArea = React.createRef();

    }

    componentDidUpdate(prevProps) {

        if (this.props?.tracking?.trackingData?.trackingResponses?.length !== prevProps?.tracking?.trackingData?.trackingResponses?.length) {
            if (this.state.searchBy === 'invoice') {
                if (this.props?.tracking?.trackingData?.trackingResponses?.length > 1) {
                    this.setState({
                        showErrMessege: true
                    })
                    // this.props.clearTracking()
                }
                else {
                    if (!this.state.showErrMessege) {
                        this.setState({
                            showErrMessege: false,
                            selectedIndex: 0
                        })
                        if (this.props?.tracking?.trackingData?.trackingResponses[0]?.waybillNo)
                            this.props.getThcDetails(this.props?.tracking?.trackingData?.trackingResponses[0]?.waybillNo);

                    }
                }
            }
        }

        if ((this?.props?.location?.state?.waybillNo || this?.props?.location?.state?.invoiceNumber) && this.props?.keys?.keysData?.accessKeys && this.state.apiCalled) {
            this.onTrackClick();
            let partnerId = this.props?.keys?.keysData?.accessKeys[0]['accessKey'];
            let clientId = this.props?.keys?.keysData?.accessKeys[1]['accessKey'];
            // <p>Contact: <b><span className="lsn-textarea" ref={this.copyArea}>{routingData.vehicleTrackerBO.assetBO.contactNumber} </span></b><Icon onClick={this.copyToClipboard} type="copy" /><span className="grren">{this.state.copySuccess}</span></p> 
            this.setState({
                apiCalled: false, text: <Card>
                    <CopyToClipboard text={`https://tracking.loadshare.net/partner/${partnerId}/client/${clientId}`}
                        onCopy={this.notifyCopyEvent}>
                        <div>
                            <a href={`https://tracking.loadshare.net/partner/${partnerId}/client/${clientId}`}>https://tracking.loadshare.net/partner/{partnerId}/client/{clientId}</a>
                            <Icon type="copy" />
                        </div>
                    </CopyToClipboard>

                </Card>
            })
        }
    }
    notifyCopyEvent = () => {
        openNotification({
            message: 'Copied',
            description: 'Tracking details has been copied to clipboard',
            className: 'warning'
        })
    }

    download = (img) => {
        fetch(img, {
            headers: {
                'Content-Type': 'image/*',
                'Content-Disposition': 'attachment; filename=image.jpg',
                'Accept': 'image/*',
                'responseType': 'blob'
            }
        }).then(response => {
            if (_.hasIn(response, 'config')) {
                saveAs(response.config.url, "downlaod");
            } else {
                saveAs(response.url, "downlaod");
            }

        })
    }



    copyToClipboard = () => {
        const el = document.createElement('textarea');
        el.value = this.copyArea.current.innerText;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ copySuccess: 'Copied!' }, () => {
            setTimeout(() => {
                this.setState({ copySuccess: '' });
            }, 1000)
        });
    };

    trackingCopyToClipboard = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ trackingCopySuccess: 'Copied!', copied: true }, () => {
            setTimeout(() => {
                this.setState({ trackingCopySuccess: '' });
            }, 1000)
        });
    };


    onInputChange = event => {
        if (event.target.value) {
            this.setState({
                inputData: event.target.value,
                trackButtonDisabled: false
            });
        } else {
            this.setState({
                inputData: event.target.value,
                trackButtonDisabled: true
            });
        }

    };

    onTrackClick = () => {
        this.setState({
            showErrMessege: false,
            expand: true,
            activeKey: 1,
            selectedIndex: null,
            isfirstLoad: false
        })
        let wayBill = this.state.inputData;
        const that = this;
        let partnerId = this.props?.keys?.keysData?.accessKeys[0]['accessKey'];
        let clientId = this.props?.keys?.keysData?.accessKeys[1]['accessKey'];
        if (this.state.searchBy === 'invoice') {
            this.props.getTrackingDetails(partnerId, clientId, wayBill, true);
        } else {
            if (wayBill) {
                this.props.getTrackingDetails(partnerId, clientId, wayBill);
                this.props.getThcDetails(wayBill);
                
            }
        }

    }

    getNextStop = (data) => {

        let nextStop;
        data.some(x => {
            let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
            if (x.status === "PENDING") {
                return nextStop = x[setConsignMent]['name'];
            }
        })
        return nextStop;
    }

    openPOD = (consigns, tracking, index) => {
        let { consign, settings } = this.state;
        // console.log("consigns->>", consigns)
        
        let cons = {
            "podImpLink": consigns,
            waybillNo: tracking.waybillNo
        }

        // console.log("consign", cons)
        // consign = {
        //     ...consigns,
        //     waybillNo: tracking.waybillNo
        // }
        settings = {
            ...settings,
            currentSlide: index,
            initialSlide: index
        }
        this.setState({ consign: cons, podImageIndex: index, settings }, () => {
            this.setState({ consignVisible: true })
        })



        // let isMultiple = false;


        // let images = tracking.consignmentPODBOs.map(t => 
        //     {
        //         if(t.podImpLink.includes(",")) {
        //             let img = t.podImpLink.split(",");
        //             isMultiple = true;
        //             return img
        //         } else {
        //             isMultiple = false;
        //             return  t.podImpLink;
        //         }

        //     }
        //     );




        // let images = row.podImpLink;
        // let consignImages = [];
        // if (images.includes(",")) {
        //     consignImages = images.split(",");
        // } else {
        //     consignImages[0] = images;
        // }
        // this.setState({ consignVisible: true, consign: consign, consignImages: images, podImageIndex: 0 })
    }

    endConsign = () => {
        let { settings } = this.state;
        settings = {
            ...settings,
            currentSlide: 0,
            initialSlide: 0
        }
        this.setState({ consignVisible: false, settings })
    }
    goBack = () => {
        this.props.history.goBack()
    }

    download = e => {
        console.log(e);
        // var element = document.createElement("a");
        // var file = new Blob(
        //     [
        //         e
        //     ],
        //     { type: "image/*" }
        // );
        // console.log("file->>", file)
        // element.href = URL.createObjectURL(file);
        // element.download = "image.jpg";
        // element.click();
        

        var reader = new FileReader();

        reader.onloadend = function () {
            var base64 = reader.result;
            var link = document.createElement("a");

            document.body.appendChild(link); // for Firefox

            link.setAttribute("href", base64);
            link.setAttribute("download", "image.jpg");
            link.click();
        };

        var file = new Blob(
            [
                e
            ],
            { type: "image/*" }
        );
        reader.readAsDataURL(file);


        // var a = document.getElementById("a");
        // var file = new Blob([e.target.heref], { type: type });
        // a.href = URL.createObjectURL(file);
        // a.download = name;
    }

    handleSearchBy = value => {
        this.setState({
            searchBy: value
        })
    }

    callback = value => {
        this.setState({ activeKey: value });
    }

    getIndex = index => {
        this.setState({ selectedIndex: index, expand: true });
        this.setState({ activeKey: 0 });
        this.props.getThcDetails(this.props?.tracking?.trackingData?.trackingResponses[index]?.waybillNo);
    }

    expandActivity = (val) => {
        this.setState({ expand: val })
    }

    showShareModal = () => {
        this.setState({ showModal: true });

        if (this.props?.keys?.keysData?.accessKeys) {
            // this.onTrackClick();
            let partnerId = this.props?.keys?.keysData?.accessKeys[0]['accessKey'];
            let clientId = this.props?.keys?.keysData?.accessKeys[1]['accessKey'];
            let URL = '';
            if (process.env.NODE_ENV === 'production') {
                URL = `https://tracking.loadshare.net/partner/${partnerId}/client/${clientId}`;
            } else {
                URL = `https://staging-tracking.loadshare.net/partner/${partnerId}/client/${clientId}`;
            }


            this.setState({ URL })
        }
    }

    hidShareModal = () => {
        this.setState({ showModal: false, URL: '', copied: false });
    }

    render() {
        let routingData = this.props?.thc?.thcData;




        let nextStop;
        let content;
        if (routingData) {
            //nextStop = this.getNextStop(routingData?.thcRouteMappingBOS);
            content = (
                <div>
                    <p>Name: <b>{routingData?.vehicleTrackerBO?.assetBO?.driverName?.toLowerCase()}</b></p>
                    <p>Contact: <b><span className="lsn-textarea" ref={this.copyArea}>{routingData?.vehicleTrackerBO?.assetBO?.contactNumber} </span></b><Icon onClick={this.copyToClipboard} type="copy" /><span className="grren">{this.state.copySuccess}</span></p>
                </div>
            );
        }
        // let isShowLoader = !routingData;
        // if (isShowLoader) {
        //     return (<Row><div className="noData"><img className="loading" src={img} alt="Loading" /></div></Row>)
        // }

        const columns = [
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                width: 70
            },
            {
                title: 'Events Date & Time',
                dataIndex: 'eventTime',
                key: 'eventTime',
                width: 70,
                render: (data) => moment(data).format("DD/MM/YYYY hh:mm A")
            },
            {
                title: 'Activity',
                dataIndex: 'event',
                key: 'event',
                width: 70
            },

        ];

        const invoiceColumns = [
            {
                title: 'WayBill No',
                dataIndex: 'waybillNo',
                key: 'waybillNo',
                width: 70,
                render: (text, record, index) => {

                    return (
                        <>
                            <div>{text}
                                <CopyToClipboard text={text}
                                    onCopy={this.notifyCopyEvent}>
                                    <span>
                                        <Icon style={{ cursor: 'pointer' }} type="copy" />
                                    </span>
                                </CopyToClipboard>
                            </div>

                        </>
                    )


                }

            },
            {
                title: 'Origin (Pickup)',
                dataIndex: 'from',
                key: 'from',
                width: 70,
                // render: (text, record, index) => {
                //     if (this.state.selectedIndex == index) {
                //         return <span style={{ backgroundColor: '#91bde5' }}>{text}</span>
                //     } else {
                //         return <span>{text}</span>
                //     }
                // }
            },
            {
                title: 'Destination',
                dataIndex: 'to',
                key: 'to',
                width: 70
            }
        ]
        let invoiceData = this.props?.tracking?.trackingData?.trackingResponses;


        let data = [];
        let trackingDetails = [];
        let deliveredOn = null;

        if (this.state.searchBy == 'invoice' && this.state.selectedIndex != null) {

            trackingDetails = this.props?.tracking?.trackingData?.trackingResponses[this.state.selectedIndex];
            if (trackingDetails) {
                let newData = [...trackingDetails?.trackingEvents];
                deliveredOn = newData?.filter(e => e.event.includes('Consignment delivered'))
                data = [...trackingDetails?.trackingEvents];
                data = data.reverse();
            }


        }
        else if (this.state.searchBy == 'docket') {
            trackingDetails = this.props?.tracking?.trackingData?.trackingResponses[0];

            if (trackingDetails) {
                let newData = [...trackingDetails?.trackingEvents];

                deliveredOn = newData?.filter(e => e.event.includes('Consignment delivered'))
                data = [...trackingDetails?.trackingEvents];
            }


        }

        let isMultiple = false;
        let consignImages = [];
        let detailsTrakcing = [];
        let forwardedTime = null;
        let consignmentTime = null;
        if (trackingDetails?.consignmentPODBOs && trackingDetails?.consignmentPODBOs.length > 0) {
            detailsTrakcing = trackingDetails?.consignmentPODBOs.map((consign, index) => {
                if (consign?.podImpLink) {
                    if (consign.podImpLink.includes(",")) {
                        let img = consign.podImpLink.split(",");
                        isMultiple = true;
                        consignImages = consign.podImpLink.split(",");
                    } else {
                        consignImages = [consign.podImpLink]
                    }
                }
            });
        }



        data.forEach((x, index) => {
            if (x.event.includes("Forwarded for Delivery")) {
                forwardedTime = index;
            }
            if (x.event.includes("Consignment delivered")) {
                consignmentTime = index;
            }
        })


        if (forwardedTime != null && consignmentTime != null) {

            if (data[forwardedTime].eventTime === data[consignmentTime].eventTime) {
                if (forwardedTime > consignmentTime) {

                    let temp = data[forwardedTime];
                    data[forwardedTime] = data[consignmentTime];
                    data[consignmentTime] = temp;
                }
            }
        }

        // trackingDetails?.consignmentPODBOs && trackingDetails?.consignmentPODBOs.length > 0 &&


        // console.log("images->>", consignImages)


        // const deliveredOn = trackingDetails?.trackingEvents?.filter(e => e.event == 'Consignment delivered.')



        return (
            <>
                <Row>
                    <Col>

                        <div className="Tracking">
                            <Card bordered={false} className="Card gutter">
                                <Row>
                                    <Col span={2}>
                                        {(this?.props?.location?.state?.waybillNo || this?.props?.location?.state?.invoiceNumber) && <div className="go-back"> <Icon style={{ cursor: 'pointer', fontSize: '25px', position: 'relative', left: 3, top: 4 }} onClick={this.goBack} type="arrow-left" /> </div>}
                                    </Col>
                                    <Col span={22}>
                                        {!this.state.showModal ?
                                            <div className="tracking-share">
                                                <Button onClick={this.showShareModal} type="default" className="share-button"><span>Copy & share this to track dockets <Icon className="share-icon" type="share-alt" /></span></Button>
                                            </div> :
                                            <div className="tracking-share">
                                                <Button style={{ zIndex: 0 }} type="default" className="share-button"><span>Copy & share this to track dockets <Icon className="share-icon" type="share-alt" /></span></Button>
                                            </div>
                                        }
                                        <Modal
                                            width={329}
                                            maskClosable={false}
                                            style={{ top: 159, left: 527 }}
                                            bodyStyle={{
                                                borderRadius: "4px",
                                                boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
                                                border: "solid 1px #f1f2f6",
                                                backgroundColor: "#edf3ff"
                                            }}
                                            zIndex={2}
                                            visible={this.state.showModal}
                                            onCancel={this.hidShareModal}
                                            footer={false}
                                            mask={false}

                                        >
                                            <div className="share-modal-title">
                                                Copy & share this to track dockets
                                           </div>
                                            <div className="share-modal-subtitle">
                                                Use this URL for tracking without login in
                                           </div>
                                            <div>
                                                <Input
                                                    disabled
                                                    value={this.state.URL.substring(0, 30) + '...'}
                                                    className="share-modal-input"
                                                    suffix={<Tooltip title={this.state.copied ? 'copied' : 'copy'}><span onClick={() => this.trackingCopyToClipboard(this.state.URL)} className={this.state.copied ? 'share-copy-green' : 'share-copy-primary'}><Icon className={this.state.copied ? 'cursor-default' : 'cursor-pointer'} style={{ color: '#fff', marginTop: 8, marginLeft: 10, fontSize: 19 }} type="copy" /></span></Tooltip>}
                                                />
                                            </div>

                                            {/* <CopyToClipboard text={this.state.URL}
                                                onCopy={this.notifyCopyEvent}>
                                                <div>
                                                    <a href={this.state.URL}>{this.state.URL}</a>
                                                    <Icon type="copy" />
                                                </div>
                                            </CopyToClipboard> */}
                                        </Modal>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col span={24}>
                                        <Typography
                                            style={{
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                fontSize: "26px"
                                            }}
                                        >
                                            Enter Docket No. / Invoice No.
                            </Typography>
                                    </Col>
                                </Row>


                                <br />

                                <Row gutter={[16, 32]}>
                                    <Col span={12} offset={6}>
                                        <Row gutter={[16, 32]}>
                                            <Col>
                                                <Radio.Group defaultValue="invoice" value={this.state.searchBy} onChange={e => this.handleSearchBy(e.target.value)}>
                                                    <Radio value={"invoice"}>Invoice No.</Radio>
                                                    <Radio value={"docket"}>Docket No.</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Row gutter={[0, 32]}>
                                            <Col span={20}>
                                                <Input
                                                    placeholder={this.state.inputPlaceholder}
                                                    value={this.state.inputData}
                                                    size="large"
                                                    allowClear
                                                    className="tracking-input"
                                                    style={{ height: '54px' }}
                                                    onChange={this.onInputChange}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                {
                                                    this.state.trackButtonDisabled ?
                                                        <Button
                                                            onClick={this.onTrackClick}
                                                            // className="tracking-primary-btn"
                                                            // style={{ opacity: '0.5' }}
                                                            style={{
                                                                height: "54px",
                                                                boxShadow: "0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06)",
                                                                border: "solid 1px #1461d2",
                                                                backgroundImage: "linear-gradient(to top, #1665d8, #1f6fe5)",
                                                                color: "white",
                                                                width: "136px",
                                                                borderRadius: 0
                                                            }}
                                                            disabled={this.state.trackButtonDisabled}
                                                            size={"large"}
                                                        >
                                                            <Icon type="search" />
                                                            Track
                                                </Button> :
                                                        <Button
                                                            onClick={this.onTrackClick}
                                                            style={{
                                                                height: "54px",
                                                                boxShadow: "0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06)",
                                                                border: "solid 1px #1461d2",
                                                                backgroundImage: "linear-gradient(to top, #1665d8, #1f6fe5)",
                                                                color: "white",
                                                                width: "136px",
                                                                borderRadius: 0
                                                            }}
                                                            disabled={this.state.trackButtonDisabled}
                                                            size={"large"}
                                                        >
                                                            <Icon type="search" />

                                                            Track
                                            </Button>

                                                }
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 32]}>
                                            <Col>
                                                {
                                                    this.state.showErrMessege
                                                        ? <Col>
                                                            <div>
                                                                {/* <span className="error-messege"> {this.state.errorMessege} </span> */}
                                                                <Collapse activeKey={this.state.activeKey} onChange={this.callback} accordion expandIconPosition="left" defaultActiveKey={['1']} >
                                                                    <Panel header={<div style={{ color: 'red' }}>{this.state.errorMessege}</div>} key="1">
                                                                        <Table
                                                                            columns={invoiceColumns}
                                                                            dataSource={invoiceData}
                                                                            pagination={false}
                                                                            // rowClassName={"invoice-rows"}
                                                                            rowClassName={(record, index) => {
                                                                                return this.state.selectedIndex == index ? 'table-row-light' : 'invoice-rows'
                                                                            }}
                                                                            bordered
                                                                            onRow={(record, rowIndex) => {
                                                                                return {
                                                                                    onClick: event => {
                                                                                        // console.log("row->>", record, rowIndex)
                                                                                        this.getIndex(rowIndex);
                                                                                    } // click row                                                  
                                                                                };
                                                                            }}
                                                                        />
                                                                    </Panel>
                                                                </Collapse>
                                                            </div>
                                                        </Col>
                                                        : <></>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>



                                {/* <Row> */}
                                {/* <Col span={17} offset={6}>
                                        <div className="gutter">
                                            <Radio.Group defaultValue="invoice" value={this.state.searchBy} onChange={e => this.handleSearchBy(e.target.value)}>
                                                <Radio value={"invoice"}>Invoice No.</Radio>
                                                <Radio value={"docket"}>Docket No.</Radio>
                                            </Radio.Group>
                                        </div>
                                    </Col> */}
                                {/* <Col span={14} push={6}>
                                        <Input
                                            placeholder={this.state.inputPlaceholder}
                                            value={this.state.inputData}
                                            allowClear
                                            style={{ width: '420px', height: '54px', borderRadius: 0 }}
                                            onChange={this.onInputChange}
                                        />
                                    </Col> */}
                                {/* <Col span={5}>
                                        {
                                            this.state.trackButtonDisabled ?
                                                <Button
                                                    onClick={this.onTrackClick}
                                                    // className="tracking-primary-btn"
                                                    // style={{ opacity: '0.5' }}
                                                    style={{
                                                        height: "54px",
                                                        boxShadow: "0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06)",
                                                        border: "solid 1px #1461d2",
                                                        backgroundImage: "linear-gradient(to top, #1665d8, #1f6fe5)",
                                                        color: "white",
                                                        width: "136px",
                                                        borderRadius: 0
                                                    }}
                                                    disabled={this.state.trackButtonDisabled}
                                                    size={"large"}
                                                >
                                                    <Icon type="search" />
                                                
                                                    Track
                                                </Button> :
                                                <Button
                                                    onClick={this.onTrackClick}
                                                    style={{
                                                        height: "54px",
                                                        boxShadow: "0 1px 1px 0 rgba(22, 29, 37, 0.1), inset 0 2px 0 0 rgba(255, 255, 255, 0.06)",
                                                        border: "solid 1px #1461d2",
                                                        backgroundImage: "linear-gradient(to top, #1665d8, #1f6fe5)",
                                                        color: "white",
                                                        width: "136px",
                                                        borderRadius: 0
                                                    }}
                                                    disabled={this.state.trackButtonDisabled}
                                                    size={"large"}
                                                >
                                                    <Icon type="search" />
                                                 
                                                    Track
                                            </Button>

                                        }
                                    </Col> */}

                                {/* {
                                        this.state.showErrMessege
                                            ? <Col span={14} offset={5}>
                                                <div className="gutter">
                                                    
                                                    <Collapse activeKey={this.state.activeKey} onChange={this.callback} accordion expandIconPosition="left" defaultActiveKey={['1']} >
                                                        <Panel header={<div style={{ color: 'red' }}>{this.state.errorMessege}</div>} key="1">
                                                            <Table
                                                                columns={invoiceColumns}
                                                                dataSource={invoiceData}
                                                                pagination={false}
                                                             
                                                                rowClassName={(record, index) => {
                                                                    return this.state.selectedIndex == index ? 'table-row-light' : 'invoice-rows'
                                                                }}
                                                                bordered
                                                                onRow={(record, rowIndex) => {
                                                                    return {
                                                                        onClick: event => {
                                                                            // console.log("row->>", record, rowIndex)
                                                                            this.getIndex(rowIndex);
                                                                        } // click row                                                  
                                                                    };
                                                                }}
                                                            />
                                                        </Panel>
                                                    </Collapse>
                                                </div>
                                            </Col>
                                            : <></>
                                    } */}
                                {/* </Row> */}

                                {/* <div className="flex justify-space-around smallMessage gutter">
                                    <Typography className="small-text">
                                        You can track multiple values with comma ( , ) or adding next line
                                     </Typography>
                                </div> */}
                            </Card>
                        </div>
                    </Col>
                </Row>
                {
                    // this.props?.tracking?.trackingData?.trackingResponses?.length > 0 &&
                    !_.isEmpty(trackingDetails) ?
                        // true &&
                        <Row gutter={12}>
                            <Col span={16}>
                                <Card title={<><img src={Paste} /> Tracking Details</>}>
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            <span>Docket No. : </span>
                                            <span><b>{trackingDetails?.waybillNo}</b> </span>
                                        </Col>
                                        <Col span={12}>
                                            <span>Invoice No. : </span>
                                            <span><b>{trackingDetails?.invoicNo ? trackingDetails?.invoicNo : (this.state.searchBy == 'invoice' ? this.state.inputData : 'NA')}</b> </span>

                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            <span>Origin (Pickup) : </span>
                                            <span><b>{trackingDetails?.from}</b> </span>
                                        </Col>
                                        <Col span={12}>
                                            <span>Shipments : </span>
                                            <span><b>{trackingDetails?.totalShipmentCount} Nos</b> </span>

                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            <span>Destination : </span>
                                            <span><b>{trackingDetails?.to}</b></span>
                                        </Col>
                                        <Col span={12}>
                                            <span>Weight(s) :</span>
                                            <span><b>{trackingDetails?.totalChargeableWeight} Kgs</b> </span>

                                        </Col>
                                    </Row>
                                    <Row gutter={[12, 12]}>
                                        <Col span={12}>
                                            {deliveredOn[0]?.event.includes('Consignment delivered') && deliveredOn[0]?.data && deliveredOn[0]?.data?.podCreatedDate &&
                                                <>
                                                    <span>Delivered on: </span>
                                                    <span><b>{new moment(Number(deliveredOn[0]?.data && deliveredOn[0]?.data?.podCreatedDate)).format('DD/MM/YYYY hh:mm A')}</b></span>
                                                </>
                                            }
                                        </Col>
                                        <Col span={12}>
                                            {/* {
                                                trackingDetails?.consignmentPODBOs && trackingDetails?.consignmentPODBOs.length > 0
                                                    ? <><span>e-POD(s) : </span>
                                                        <span><b>{
                                                            trackingDetails?.consignmentPODBOs.map((consign, index) => (
                                                                consign?.podImpLink ?

                                                                    <Tag style={{ cursor: 'pointer' }} color="#6758f3" onClick={() => this.openPOD(consign, trackingDetails)}>E-POD {index + 1}</Tag>
                                                                    : <>NA</>
                                                            ))}
                                                          
                                                        </b>
                                                        </span></>
                                                    : <></>
                                            } */}
                                            {
                                                consignImages && consignImages.length > 0 ?
                                                    <>
                                                        <span>e-POD(s): </span>
                                                        <span><b>{
                                                            consignImages.map((consign, index) => (
                                                                <Tag style={{ cursor: 'pointer' }} color="#6758f3" onClick={() => this.openPOD(consign, trackingDetails, index)}>E-POD {index + 1}</Tag>
                                                            ))
                                                        }

                                                        </b>

                                                        </span>
                                                    </> : <></>
                                            }
                                        </Col>
                                    </Row>


                                </Card>
                                <Spin spinning={this.props?.tracking?.loading}>
                                    {data.length <= 2 ?
                                        <Table
                                            columns={columns}
                                            dataSource={[...data]}
                                            bordered
                                            pagination={false}
                                        /> :
                                        <>
                                            {this.state.expand ?
                                                <>
                                                    <Table
                                                        columns={columns}
                                                        dataSource={[...data.splice(0, 2)]}
                                                        bordered
                                                        pagination={false}

                                                    />
                                                    <Button style={{ width: '100%' }} size={"large"} onClick={() => this.expandActivity(false)}>View All Activity</Button>
                                                </>
                                                :
                                                <>
                                                    <Table
                                                        columns={columns}
                                                        dataSource={[...data]}
                                                        bordered
                                                        pagination={false}
                                                    />
                                                    <Button style={{ width: '100%' }} size={"large"} onClick={() => this.expandActivity(true)}>Hide Activity</Button>
                                                </>
                                            }
                                        </>

                                    }





                                </Spin>

                                <br />
                                <br />

                            </Col>
                            <Col span={8}>
                                <Row className="">
                                    <Col className="gutter-row" span={24}>
                                        {/* <div style={{ textAlign: 'right' }}>
                                        <Tooltip placement="leftTop" title={this.state.text}>
                                            <Icon style={{ fontSize: '20px' }} type="info-circle" />
                                        </Tooltip>

                                    </div> */}

                                        <div className="gutter-box tracking-map">
                                            {routingData && routingData?.responses && routingData?.responses.length > 0 ? <HeliosRoute visibleFullScreen={true} routing={routingData} {...this.props} /> :
                                                routingData && routingData?.responses.length == 0 ?
                                                    <Card style={{ textAlign: 'center' }}>
                                                        <div style={{
                                                            margin: "auto",
                                                            width: "50%",
                                                            padding: "15%"
                                                        }}>
                                                            <img src={Truck} />
                                                        </div>
                                                        <p>Live tracking not available</p>
                                                    </Card> :
                                                    <div className="noData"><img className="loading" src={img} alt="Loading" /></div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                {routingData && routingData?.responses && routingData?.responses.length > 0 &&
                                    <Row>
                                        <Col span={24}>

                                            <div className="route-card">
                                                <div className="Route-Activity" style={{ float: 'left' }}>Route Activity</div>
                                                {/* {
                                                routingData?.vehicleTrackerBO?.tripId && <div onClick={e => this.props.history.push(`/appv2/customer-tracking/dashboard/customer-route-tracking/${routingData?.vehicleTrackerBO?.tripId}`)} className="Route-Activity" style={{ float: 'right', cursor: 'pointer' }}>
                                                    <Icon type="fullscreen" />
                                                </div>
                                            } */}
                                                <div className="divider"></div>
                                                <div className="stepper">
                                                    {routingData && routingData?.responses && routingData?.responses.length > 0 &&
                                                        <HeliosStepper tripData={routingData}></HeliosStepper>
                                                    }
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                }
                            </Col>
                            <Modal
                                width={800}
                                title={this.state?.consign?.waybillNo}
                                visible={this.state.consignVisible}
                                onCancel={this.endConsign}
                                footer={[]}
                            >
                                <Row>
                                    {/* <Col span={20} push={2}> */}
                                    {/* <div style={{ textAlign: 'center' }}>
                                            <img style={{ height: 450 }} src={this.state?.consign?.podImpLink} />
                                        </div> */}

                                    {/* <div style={{ textAlign: 'center' }}>
                                            <div style={{ height: 400 }}>
                                                <img style={{ maxHeight: '100%', width: '100%', objectFit: 'contain' }} src={this.state?.consign?.podImpLink} />
                                            </div>
                                        </div>


                                    </Col> */}

                                    <Col span={20} push={2}>
                                        <div style={{ textAlign: 'center' }}>

                                            <Slider {...this.state.settings}>



                                                {consignImages.map((img, index) => (
                                                    <div>
                                                        <div style={{ height: 400 }}>
                                                            <div style={{
                                                                fontSize: "16px",
                                                                fontWeight: 700
                                                            }}>E-POD - {this.state.podImageIndex + 1}</div>
                                                            <img style={{ maxHeight: '100%', width: '100%', objectFit: 'contain' }} src={consignImages[this.state.podImageIndex]} />
                                                        </div>
                                                    </div>
                                                ))}




                                            </Slider>
                                        </div>

                                    </Col>
                                    <Col span={1} push={3}>
                                        <a
                                            // href={this.state?.consign?.podImpLink}
                                            // download={this.state?.consign?.podImpLink}
                                            // target="_blank"
                                            onClick={e => this.download(this.state?.consign?.podImpLink)}
                                        >
                                            <div >
                                                <Icon style={{
                                                    fontSize: "16px",
                                                    background: "#1890ff",
                                                    padding: "4px",
                                                    color: "#fff",
                                                    borderRadius: "4px",
                                                    cursor: "pointer"
                                                }} type="download" />
                                            </div>

                                        </a>
                                    </Col>


                                </Row>


                            </Modal>


                        </Row>
                        : this.state.isfirstLoad ? <></> : <Empty />
                }
                {
                    this.props?.tracking?.trackingData?.trackingResponses?.length <= 0 &&
                    <Empty />
                }
            </>

        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    keys: state?.customerTracking?.keys,
    tracking: state?.customerTracking?.tracking,
    thc: state?.customerTracking?.thc

});
const mapDispatchToProps = dispatch => ({
    getAccessKeys: () => dispatch(actions.getAccessKeys()),
    getTrackingDetails: (partnetId, clientId, waybill, isInvoice) => dispatch(actions.getTrackingDetails(partnetId, clientId, waybill, isInvoice)),
    getThcDetails: (wayBill) => dispatch(actions.getThcDetails(wayBill)),
    resetFields: () => dispatch(actions.resetFields()),
    clearTracking: () => dispatch(actions.clearTracking())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTracking);
// export default CustomerTracking;